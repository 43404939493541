import React from 'react';
import { ReferenceField, ReferenceManyField, SingleFieldList, ChipField } from 'react-admin';

const ReferenceManyToManyField = ({
  linkResName,
  fromResIdName,
  toResIdName,
  toResName,
  label,
  ...restProps
}) => (
  <ReferenceManyField
    label={label}
    reference={linkResName}
    target={fromResIdName}
    sort={{ field: 'id', order: 'ASC' }}
    {...restProps}
  >
    <SingleFieldList linkType={false}>
      <ReferenceField source={toResIdName} reference={toResName}>
        {/* onClick is workaround for Chip to act as clickable */}
        <ChipField source="name" onClick={() => {}} />
      </ReferenceField>
    </SingleFieldList>
  </ReferenceManyField>
);

ReferenceManyToManyField.defaultProps = {
  addLabel: true,
};

export default ReferenceManyToManyField;
