import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  Toolbar,
  SaveButton,
  DeleteButton,
  Create,
  DisabledInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  EmailField,
  DateField,
  LongTextInput,
  required,
  UrlField,
  Filter,
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import LongTextField from './fields/LongTextField';
import SelectInput from './inputs/SelectInput';
import DateInput from './inputs/DateInput';
import { generatePassword, getChoices } from '../utils.js';
import { withStyles } from '@material-ui/core';
import Query from './data/Query';
import { QUERY_PER_PAGE } from '../constants';

const TITLE_SINGULAR = 'Participant';
const TITLE_PLURAL = 'Participants';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const ParticipantTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
ParticipantTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const ParticipantFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__name_email" alwaysOn />
  </Filter>
);

export const ParticipantList = props => (
  <List
    title={TITLE_PLURAL}
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    filters={<ParticipantFilters />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="accessCode" />
      <ReferenceField source="languageId" reference="language">
        <TextField source="code" />
      </ReferenceField>
      <ReferenceField source="tourId" reference="tour">
        <TextField source="name" />
      </ReferenceField>
      <ShowButton />
    </Datagrid>
  </List>
);

export const ParticipantShow = props => (
  <Show title={<ParticipantTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <ReferenceField source="tourId" reference="tour">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="accessCode" />
      <ReferenceField source="languageId" reference="language">
        <TextField source="code" />
      </ReferenceField>
      <UrlField source="routeBookUrl" />
      <TextField source="suggestedBikeModel" />
      <TextField source="suggestedBikeSize" />
      <ReferenceField source="bikeId" reference="bike" allowEmpty>
        <TextField source="number" />
      </ReferenceField>
      <TextField source="riderType" />
      <TextField source="healthConditions" />
      <TextField source="dietaryRestrictions" />
      <TextField source="jerseySize" />
      <TextField source="bibShortSize" />
      <TextField source="helmetSize" />
      <TextField source="pedalSystem" />
      <TextField source="height" />
      <TextField source="arrivalAirport" />
      <TextField source="arrivalFlightNumber" />
      <DateField source="arrivalDate" locales="pt-PT" />
      <TextField source="departureAirport" />
      <TextField source="departureFlightNumber" />
      <DateField source="departureDate" locales="pt-PT" />
      <TextField source="seatpostNoseToHandlebarCentre" />
      <TextField source="handlebarCentreToFrontAxle" />
      <TextField source="seatpostNoseToPedalAxle" />
      <TextField source="seatpostTopToPedalAxle" />
      <TextField source="roommateName" />
      <TextField source="preferredBedType" />
      <TextField source="withAdditionalMeals" />
      <LongTextField source="comments" />
    </SimpleShowLayout>
  </Show>
);

const ParticipantEditToolbar = withRouter(
  withStyles(toolbarStyles)(({ location, staticContext, ...restProps }) => {
    const locationState = location.state;
    const redirect = locationState && locationState.redirect;
    return (
      <Toolbar {...restProps}>
        <SaveButton redirect={redirect} undoable={false} />
        <DeleteButton redirect={redirect} undoable={false} />
      </Toolbar>
    );
  }),
);
ParticipantEditToolbar.displayName = 'ParticipantEditToolbar';

const BikeInput = () => (
  <Query onlyData type="GET_ALL" resource="bikeModel" errorMsg="Failed to fetch bike models.">
    {bikeModels => (
      <Query onlyData type="GET_ALL" resource="bike" errorMsg="Failed to fetch bikes.">
        {bikes => {
          const choicesWithModel = getChoices(bikes, bikeModels, 'modelId', {
            resNameField: (bike, model) => `${bike.number} - ${model.name} - ${bike.size}`,
            sortBy: ['modelId', 'number'],
          });

          return <SelectInput label="Bike" source="bikeId" choices={choicesWithModel} />;
        }}
      </Query>
    )}
  </Query>
);

export const ParticipantEdit = props => (
  <Edit title={<ParticipantTitle />} {...props}>
    <SimpleForm toolbar={<ParticipantEditToolbar />}>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="email" validate={[required()]} />
      <TextInput source="phoneNumber" />
      <ReferenceInput
        perPage={QUERY_PER_PAGE}
        source="tourId"
        reference="tour"
        validate={[required()]}
      >
        <SelectInput optionText="name" disabled />
      </ReferenceInput>
      <TextField source="accessCode" style={{ fontSize: '1rem' }} />
      <ReferenceInput
        perPage={QUERY_PER_PAGE}
        source="languageId"
        reference="language"
        validate={[required()]}
      >
        <SelectInput optionText="code" />
      </ReferenceInput>
      <UrlField source="routeBookUrl" label="Route book" />
      <BikeInput />
      <TextInput source="suggestedBikeModel" />
      <TextInput source="suggestedBikeSize" />
      <TextInput source="riderType" />
      <TextInput source="healthConditions" />
      <TextInput source="dietaryRestrictions" />
      <TextInput source="jerseySize" />
      <TextInput source="bibShortSize" />
      <TextInput source="helmetSize" />
      <TextInput source="pedalSystem" />
      <TextInput source="height" />
      <TextInput source="arrivalAirport" />
      <TextInput source="arrivalFlightNumber" />
      <DateInput source="arrivalDate" />
      <TextInput source="departureAirport" />
      <TextInput source="departureFlightNumber" />
      <DateInput source="departureDate" />
      <TextInput source="seatpostNoseToHandlebarCentre" />
      <TextInput source="handlebarCentreToFrontAxle" />
      <TextInput source="seatpostNoseToPedalAxle" />
      <TextInput source="seatpostTopToPedalAxle" />
      <TextInput source="roommateName" />
      <TextInput source="preferredBedType" />
      <TextInput source="withAdditionalMeals" />
      <LongTextInput source="comments" />
    </SimpleForm>
  </Edit>
);
ParticipantEdit.propTypes = { location: PropTypes.object.isRequired };

export const ParticipantCreate = props => {
  const locationState = props.location.state;
  return (
    <Create {...props}>
      <SimpleForm redirect={locationState && locationState.redirect}>
        <TextInput source="name" validate={[required()]} autoFocus />
        <TextInput source="email" validate={[required()]} />
        <TextInput source="phoneNumber" />
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="tourId"
          reference="tour"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="languageId"
          reference="language"
          validate={[required()]}
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <DisabledInput source="routeBookUrl" />
        <DisabledInput
          source="accessCode"
          defaultValue={generatePassword}
          validate={[required()]}
        />
        <TextInput source="suggestedBikeModel" />
        <TextInput source="suggestedBikeSize" />
        <BikeInput />
        <TextInput source="riderType" />
        <TextInput source="healthConditions" />
        <TextInput source="dietaryRestrictions" />
        <TextInput source="jerseySize" />
        <TextInput source="bibShortSize" />
        <TextInput source="helmetSize" />
        <TextInput source="pedalSystem" />
        <TextInput source="height" />
        <TextInput source="arrivalAirport" />
        <TextInput source="arrivalFlightNumber" />
        <DateInput source="arrivalDate" />
        <TextInput source="departureAirport" />
        <TextInput source="departureFlightNumber" />
        <DateInput source="departureDate" />
        <TextInput source="seatpostNoseToHandlebarCentre" />
        <TextInput source="handlebarCentreToFrontAxle" />
        <TextInput source="seatpostNoseToPedalAxle" />
        <TextInput source="seatpostTopToPedalAxle" />
        <TextInput source="roommateName" />
        <TextInput source="preferredBedType" />
        <TextInput source="withAdditionalMeals" />
        <LongTextInput source="comments" />
      </SimpleForm>
    </Create>
  );
};
ParticipantCreate.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};
