import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  Filter
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import SelectInput from './inputs/SelectInput';
import { QUERY_PER_PAGE } from '../constants';

const TITLE_SINGULAR = 'Bike';
const TITLE_PLURAL = 'Bikes';

const BikeTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.number}"
  </span>
);
BikeTitle.propTypes = { record: PropTypes.shape({ number: PropTypes.number }) };

const BikeFilters = props => (
  <Filter {...props}>{/* <TextInput label="Search" source="number" alwaysOn /> */}</Filter>
);

export const BikeList = props => (
  <List
    title={TITLE_PLURAL}
    {...props}
    sort={{ field: 'model.name', order: 'ASC' }}
    filters={<BikeFilters />}
  >
    <Datagrid rowClick="edit">
      <NumberField source="number" />
      <TextField source="size" />
      <TextField source="serialNumber" />
      <TextField source="location" />
      <ReferenceField source="modelId" reference="bikeModel">
        <TextField source="name" />
      </ReferenceField>
      <ShowButton />
    </Datagrid>
  </List>
);

export const BikeShow = props => (
  <Show title={<BikeTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="number" />
      <TextField source="size" />
      <TextField source="serialNumber" />
      <TextField source="location" />
      <ReferenceField source="modelId" reference="bikeModel">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const BikeEdit = props => (
  <Edit title={<BikeTitle />} {...props}>
    <SimpleForm>
      <NumberInput source="number" validate={[required()]} />
      <TextInput source="size" validate={[required()]} />
      <TextInput source="serialNumber" />
      <TextInput source="location" validate={[required()]} />
      <ReferenceInput
        perPage={QUERY_PER_PAGE}
        source="modelId"
        reference="bikeModel"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const BikeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput source="number" validate={[required()]} autoFocus />
      <TextInput source="size" validate={[required()]} />
      <TextInput source="serialNumber" />
      <TextInput source="location" validate={[required()]} />
      <ReferenceInput
        perPage={QUERY_PER_PAGE}
        source="modelId"
        reference="bikeModel"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
