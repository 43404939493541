import { ANONYMOUS_JSON_WEB_TOKEN, HASURA_QUERY_ENDPOINT } from './constants';
const fetch = window.fetch;

const graphqlClient = async graphqlQuery => {
  const jsonWebToken = window.localStorage.getItem('jsonWebToken') || ANONYMOUS_JSON_WEB_TOKEN;
  const headers = {
    'content-type': 'application/json',
    Authorization: 'Bearer ' + jsonWebToken,
  };
  const resp = await fetch(HASURA_QUERY_ENDPOINT, {
    method: 'POST',
    headers,
    body: JSON.stringify(graphqlQuery),
  });
  if (resp.ok || resp.status === 400) {
    const data = await resp.json();
    if (data.errors) {
      throw Error('GraphQL Error: ' + JSON.stringify(data.errors));
    }
    return data.data;
  } else {
    throw Error('Network Error: ' + resp.statusText);
  }
};

export async function getSignedUrl(file) {
  const data = await graphqlClient({
    query: `
      query($filename: String!, $contentType: String!) {
        getS3UploadSignedUrl(filename: $filename, contentType: $contentType) {
          signedUrl
          publicUrl
        }
      }
    `,
    variables: {
      filename: file.name,
      contentType: file.type,
    },
  });
  return data.getS3UploadSignedUrl;
}

export async function generateTagsDocument({ tourId }) {
  const data = await graphqlClient({
    query: `
      mutation GenerateTagsDocument($tourId: Int!) {
        generateTourTagsDocument(tourId: $tourId) {
          tagsDocumentUrl
        }
      }
    `,
    variables: { tourId },
  });

  const { tagsDocumentUrl } = data.generateTourTagsDocument;
  return {
    data: tagsDocumentUrl,
  };
}

export async function clearRouteBooks({ tourId }) {
  const data = await graphqlClient({
    query: `
      mutation ClearTourRouteBooks($tourId: Int!) {
        clearTourRouteBooks(tourId: $tourId) {
          nParticipantsCleared
        }
      }
    `,
    variables: { tourId },
  });

  const { nParticipantsCleared } = data.clearTourRouteBooks;
  return {
    data: nParticipantsCleared,
  };
}

export async function generateRouteBooks({ tourId, forMissingOnly }) {
  const data = await graphqlClient({
    query: `
      mutation GenerateTourRouteBooks($tourId: Int!, $forMissingOnly: Boolean) {
        generateTourRouteBooks(tourId: $tourId, forMissingOnly: $forMissingOnly) {
          routeBookUrls
        }
      }
    `,
    variables: { tourId, forMissingOnly },
  });

  const { routeBookUrls } = data.generateTourRouteBooks;
  return {
    data: routeBookUrls,
  };
}

export async function fetchUnusedTourGroupIds() {
  const data = await graphqlClient({
    query: `
      query FetchUnusedTourGroupIds {
        fetchUnusedTourGroupIds {
          tourGroupIds
        }
      }
    `,
  });

  const { tourGroupIds } = data.fetchUnusedTourGroupIds;
  return {
    data: tourGroupIds,
  };
}

export async function fetchRouteDataFromGPX(gpxUrl) {
  const data = await graphqlClient({
    query: `
      query GetRouteDataFromGPX ($gpxUrl: String!) {
        getRouteDataFromGPX(gpxUrl: $gpxUrl) {
          name
          elevationGain
          distanceKms
          mapUrl
          altimetryUrl
          coordinates
          elevations
        }
      }
    `,
    variables: {
      gpxUrl,
    },
  });

  return {
    data: data.getRouteDataFromGPX,
  };
}

export async function syncTourParticipants({ tourId }) {
  const data = await graphqlClient({
    query: `
      mutation SyncTourParticipants($tourId: Int!) {
        syncTourParticipants(tourId: $tourId) {
          createdParticipantsIds
        }
      }
    `,
    variables: { tourId },
  });

  const { createdParticipantsIds } = data.syncTourParticipants;
  return {
    data: createdParticipantsIds,
  };
}

export async function createTourDays({ tourId, number }) {
  const data = await graphqlClient({
    query: `
      mutation CreateTourDays($tourId: Int!, $number: Int!) {
          createTourDays(tourId: $tourId, number: $number) {
            createdTourDaysIds
          }
      }
    `,
    variables: {
      tourId,
      number,
    },
  });

  const { createdTourDaysIds } = data.createTourDays;
  return {
    data: createdTourDaysIds,
  };
}

export async function connectParticipantsToTourDays({ tourId }) {
  const data = await graphqlClient({
    query: `
      mutation ConnectParticipantsToTourDays($tourId: Int!) {
          connectParticipantsToTourDays(tourId: $tourId) {
            createdLinksIds
          }
      }
    `,
    variables: {
      tourId,
    },
  });

  const { createdLinksIds } = data.connectParticipantsToTourDays;
  return {
    data: createdLinksIds,
  };
}

function setDifference(setA, setB) {
  const diff = new Set(setA);
  for (const elem of setB) {
    diff.delete(elem);
  }
  return diff;
}

export async function updateLinks({
  fromResId,
  oldDestRess,
  newDestRess,
  fromResIdName,
  toResIdName,
  linkResName,
}) {
  const oldDestRessSet = new Set(oldDestRess);
  const newDestRessSet = new Set(newDestRess);
  const destRessToDelete = setDifference(oldDestRessSet, newDestRessSet);
  const destRessToCreate = setDifference(newDestRessSet, oldDestRessSet);
  if (destRessToDelete.size > 0) {
    const deleteWhere = [...destRessToDelete]
      .map(
        destResId => `{${fromResIdName}: {_eq: ${fromResId}}, ${toResIdName}: {_eq: ${destResId}}}`,
      )
      .join(',');
    await graphqlClient({
      query: `
        mutation {
          delete_${linkResName}(where: {_or: [${deleteWhere}]}) {
            affected_rows
          }
        }
      `,
    });
  }
  if (destRessToCreate.size > 0) {
    const createWhere = [...destRessToCreate]
      .map(destResId => `{${fromResIdName}: ${fromResId}, ${toResIdName}: ${destResId}}`)
      .join(',');
    await graphqlClient({
      query: `
        mutation {
          insert_${linkResName}(objects: [${createWhere}]) {
            affected_rows
          }
        }
      `,
    });
  }
}

export async function createUser({ username, password, role }) {
  const data = await graphqlClient({
    query: `
      mutation CreateUser($username: String!, $password: String!, $role: String!) {
        createUser(username: $username, password: $password, role: $role)
      }
    `,
    variables: {
      username,
      password,
      role,
    },
  });

  const success = data.createUser;
  return {
    data: success,
  };
}

export async function login({ username, password }) {
  const data = await graphqlClient({
    query: `
      mutation LoginWithPassword($username: String!, $password: String!) {
        pwdLogin(username: $username, password: $password) {
          jsonWebToken
          userType
        }
      }
    `,
    variables: {
      username,
      password,
    },
  });

  return {
    data: data.pwdLogin,
  };
}
