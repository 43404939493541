import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { CardActions } from 'react-admin';
import ShowButton from '../button/ShowButton';

const EditActions = withRouter(({ basePath, data, location }) => {
  const { pathname } = location;
  const pathnameParts = pathname.split('/');
  pathnameParts.splice(3, 0, 'show');
  const showPathname = pathnameParts.join('/');
  return (
    <CardActions>
      <ShowButton basePath={basePath} record={data} to={showPathname} />
    </CardActions>
  );
});
EditActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
};
EditActions.displayName = 'EditActions';

export default EditActions;
