import React from 'react';
import PropTypes from 'prop-types';
import S3UploadInput from './S3UploadInput';
import { getSignedUrl } from '../../api';
import * as Sentry from '@sentry/browser';

const LLRUploadInput = ({ type, label, ...restProps }) => {
  function getDefaultLabel () {
    if (type === 'image') {
      return 'Image';
    } else if (type === 'file') {
      return 'File';
    } else {
      throw new Error(`Unknown type ${ type }, must be file or image.`);
    }
  }
  return (
    <S3UploadInput
      getSignedUrl={(file, callback) =>
        getSignedUrl(file)
          .then(callback) // eslint-disable-line
          .catch(err => {
            console.error(err);
            Sentry.captureException(err);
          })
      }
      label={label || getDefaultLabel()}
      type={type}
      {...restProps}
    />
  );
};

LLRUploadInput.propTypes = {
  type: PropTypes.oneOf(['file', 'image']),
  label: PropTypes.string,
};

export default LLRUploadInput;
