import _ from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/core';
import Query from './Query';
import SelectArrayInput from '../inputs/SelectArrayInput';
import { updateLinks } from '../../api';

const styles = {
  multiSelect: {
    minWidth: '256px',
    maxWidth: '512px',
  },
};

const inputComponentsCache = {};

const ManyToManyInputHelper = ({ children, configs, ...editProps }) => {
  // Build input components (SelectArrayInputs) using queries to fetch records
  const inputComponents = configs.map(config => {
    const cacheKey = config.fromResIdName + '-' + config.toResIdName;
    if (inputComponentsCache[cacheKey]) {
      return inputComponentsCache[cacheKey];
    }
    const ResourceInput = withStyles(styles)(({ classes }) => (
      <Query
        onlyData
        type="GET_ALL"
        resource={config.toResName}
        errorMsg={`Failed to fetch ${ config.label.toLowerCase() }.`}
      >
        {records => (
          <SelectArrayInput
            className={classes.multiSelect}
            label={config.label}
            source={config.source}
            choices={records}
          />
        )}
      </Query>
    ));
    ResourceInput.displayName = _.capitalize(config.source) + 'Input';
    inputComponentsCache[cacheKey] = ResourceInput;
    return ResourceInput;
  });

  let result = resLinksArray => {
    // Build defaultValue used to bootstrap inputs with inital values
    const defaultValue = {};
    for (let i = 0; i < configs.length; i++) {
      const config = configs[i];
      defaultValue[config.source] = resLinksArray[i].map(link => link[config.toResIdName]);
    }

    // Override save so that it creates and deletes links before actually saving the record
    const oldSave = editProps.save;
    editProps.save = async (values, redirect) => {
      let record = { ...values };
      for (let i = 0; i < configs.length; i++) {
        const config = configs[i];
        await updateLinks({
          fromResId: values.id,
          oldDestRess: resLinksArray[i].map(link => link[config.toResIdName]),
          newDestRess: values[config.source],
          fromResIdName: config.fromResIdName,
          toResIdName: config.toResIdName,
          linkResName: config.linkResName,
        });
        delete record[config.source];
      }

      return oldSave(record, redirect);
    };
    return children(editProps, defaultValue, inputComponents);
  };

  // Add queries to final handler for fetching initial links
  for (const config of configs) {
    const oldResult = result;
    // eslint-disable-next-line react/display-name
    result = resLinksArray => {
      return (
        <Query
          onlyData
          type="GET_MANY_REFERENCE"
          resource={config.linkResName}
          payload={{
            target: config.fromResIdName,
            id: editProps.record.id,
            pagination: { perPage: 100 },
            sort: { field: 'id', order: 'asc' },
          }}
          errorMsg={`Failed to fetch ${ config.label.toLowerCase() }.`}
        >
          {resLinks => oldResult([resLinks, ...resLinksArray])}
        </Query>
      );
    };
  }

  return result([]);
};

export default ManyToManyInputHelper;
