import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { login } from './api';

export default async (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { data } = await login(params);
    if (!data) {
      return Promise.reject();
    }
    window.localStorage.setItem('jsonWebToken', data.jsonWebToken);
    return Promise.resolve();
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    window.localStorage.removeItem('jsonWebToken');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    if (!params) {
      window.localStorage.removeItem('jsonWebToken');
      return Promise.reject();
    }
    if (params.message && params.message.includes('Could not verify JWT')) {
      return Promise.reject();
    }

    const { status } = params;
    if (status === 401 || status === 403) {
      window.localStorage.removeItem('jsonWebToken');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return window.localStorage.getItem('jsonWebToken') ? Promise.resolve() : Promise.reject();
  }

  // called when a component requires to check the user’s permissions.
  if (type === AUTH_GET_PERMISSIONS) {
    return window.localStorage.getItem('jsonWebToken')
      ? Promise.resolve('admin')
      : Promise.reject();
  }

  return Promise.reject('Unknown method');
};
