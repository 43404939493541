import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addField, FormInput, FieldTitle } from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import * as Sentry from '@sentry/browser';

const styles = theme =>
  createStyles({
    previewImage: {
      maxHeight: '10rem',
    },
    previewWrapper: {
      display: 'block',
      marginTop: '1.5rem',
      marginBottom: '0.5rem',
    },
    loadingSpinner: {
      display: 'block',
      height: '2rem',
      marginTop: '1.5rem',
      marginBottom: '1.3rem',
    },
    uploaderInput: {
      display: 'none',
    },
    clearButton: {
      marginLeft: '0.5rem',
      color: '#ffffff',
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: darken(theme.palette.error.main, 0.05),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

const UploadInput = ({
  source,
  resource,
  children,
  input,
  type,
  label,
  meta,
  isRequired,
  classes,
}) => {
  if (typeof meta === 'undefined') {
    throw new Error(
      "The UploadInput component wasn't called within a redux-form <Field>. " +
        'Did you decorate it and forget to add the addField prop to your component? ' +
        'See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component ' +
        'for details.'
    );
  }

  function getPreview (value) {
    if (type === 'image') {
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          <img src={value} alt="" className={classes.previewImage} />
        </a>
      );
    } else if (type === 'file') {
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    } else {
      throw new Error(`Unknown type ${ type }, must be file or image.`);
    }
  }

  const [loading, setLoading] = useState(false);
  const { value, onChange } = input;
  const { touched, error } = meta;
  const hasError = Boolean(touched && error);
  return (
    <div>
      <FormInput
        input={
          <FormControl margin="normal">
            <InputLabel shrink error={hasError} htmlFor={source}>
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            </InputLabel>
            <div className={classes.uploaderInput}>
              {children({
                onProgress: () => {
                  setLoading(true);
                },
                onError: err => {
                  console.error(err);
                  Sentry.captureException(err);
                },
                onFinish: url => {
                  onChange(url);
                  setLoading(false);
                },
              })}
            </div>
          </FormControl>
        }
      />
      {loading ? (
        <CircularProgress className={classes.loadingSpinner} size={30} />
      ) : (
        <div className={classes.previewWrapper}>{getPreview(value)}</div>
      )}

      <label htmlFor={source}>
        <Button variant="contained" component="span" color="primary">
          {value ? 'Replace' : 'Upload'}
        </Button>
      </label>

      {value && (
        <Button variant="contained" className={classes.clearButton} onClick={() => onChange(null)}>
          Clear
        </Button>
      )}

      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </div>
  );
};

UploadInput.defaultProps = {
  addLabel: true,
};

UploadInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  type: PropTypes.oneOf(['file', 'image']),
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  isRequired: PropTypes.bool,
  classes: PropTypes.object,
};

export default addField(withStyles(styles)(UploadInput));
