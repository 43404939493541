import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

const ReferenceManyFieldLoadingWrapper = ({ children, loadingText, ...restProps }) => {
  const { loadedOnce, data } = restProps;
  if (!loadedOnce) {
    return <LinearProgress />;
  }
  if (!data || Object.entries(data).length === 0) {
    return <div style={{ marginTop: '1em' }}>{loadingText}</div>;
  }
  return React.cloneElement(children, restProps);
};

ReferenceManyFieldLoadingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loadingText: PropTypes.string.isRequired,
};

export default ReferenceManyFieldLoadingWrapper;
