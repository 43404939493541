import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { CardActions } from 'react-admin';
import EditButton from '../button/EditButton';

const ShowActions = withRouter(({ basePath, data, location }) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} to={location.pathname.replace('/show', '')} />
  </CardActions>
));
ShowActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
};
ShowActions.displayName = 'ShowActions';

export default ShowActions;
