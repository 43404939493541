import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  Filter,
  LongTextInput
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import LongTextField from './fields/LongTextField';
import ManyToManyInputHelper from './data/ManyToManyInputHelper';
import { isCoordinates } from '../validators';
import ReferenceManyToManyField from './data/ReferenceManyToManyField';
import MultiLangBlock from './other/MultiLangBlock';

const TITLE_SINGULAR = 'Bike Shop';
const TITLE_PLURAL = 'Bike Shops';

const BikeShopTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
BikeShopTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const BikeShopFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__name" alwaysOn />
  </Filter>
);

export const BikeShopList = props => (
  <List
    title={TITLE_PLURAL}
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    filters={<BikeShopFilters />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="phoneNumber" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const BikeShopShow = props => (
  <Show title={<BikeShopTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="phoneNumber" />
      <TextField source="coordinates" />
      <MultiLangBlock isShow>
        <LongTextField source="workingHours" label="Working hours" />
      </MultiLangBlock>
      <ReferenceManyToManyField
        linkResName="bikeShopToRegionLink"
        fromResIdName="bikeShopId"
        toResIdName="regionId"
        toResName="region"
        label="Regions"
      />
    </SimpleShowLayout>
  </Show>
);

export const BikeShopEdit = props => (
  <Edit title={<BikeShopTitle />} {...props}>
    <ManyToManyInputHelper
      configs={[
        {
          source: 'regions',
          linkResName: 'bikeShopToRegionLink',
          fromResIdName: 'bikeShopId',
          toResIdName: 'regionId',
          toResName: 'region',
          label: 'Regions',
        },
      ]}
    >
      {(editProps, defaultValue, [RegionsInput]) => (
        <SimpleForm defaultValue={defaultValue} {...editProps}>
          <TextInput source="name" validate={[required()]} />
          <TextInput source="address" validate={[required()]} />
          <TextInput source="phoneNumber" validate={[required()]} />
          <TextInput source="coordinates" validate={[required(), isCoordinates()]} />
          <MultiLangBlock>
            <LongTextInput source="workingHours" label="Working hours" validate={[required()]} />
          </MultiLangBlock>
          <RegionsInput />
        </SimpleForm>
      )}
    </ManyToManyInputHelper>
  </Edit>
);

export const BikeShopCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} autoFocus />
      <TextInput source="address" validate={[required()]} />
      <TextInput source="phoneNumber" validate={[required()]} />
      <TextInput source="coordinates" validate={[required(), isCoordinates()]} />
      <MultiLangBlock>
        <LongTextInput source="workingHours" label="Working hours" validate={[required()]} />
      </MultiLangBlock>
    </SimpleForm>
  </Create>
);
