import React from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const styles = {
  button: {
    marginTop: '1em',
    color: '#fff !important',
  },
};

const AddNewButton = ({ classes, record, label, createPath, parentIdField, redirect }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: createPath,
      state: {
        record: { [parentIdField]: record.id },
        redirect,
      },
    }}
    label={label}
  >
    <PersonAddIcon />
  </Button>
);
AddNewButton.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  label: PropTypes.string.isRequired,
  createPath: PropTypes.string.isRequired,
  parentIdField: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
};

export default withStyles(styles)(AddNewButton);
