import _ from 'lodash';

export const isCoordinates =
  (message = 'Invalid coordinates format. Valid example: 37.723624, -8.781969') =>
  coordinates => {
  const coordinatesArray = coordinates.split(',').map(_.trim);
  if (
    coordinatesArray.length !== 2 ||
    _.some(coordinatesArray, s => !s.match(/^-?[0-9]+\.[0-9]+$/))
  ) {
    return message;
  }
};
