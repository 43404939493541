import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import {
  Toolbar,
  SaveButton,
  DisabledInput,
  Edit,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  TextField,
  LongTextInput,
  BooleanField,
  BooleanInput,
  NumberField,
  ReferenceManyField,
  ReferenceFieldController,
  SimpleShowLayout,
  required,
  LinearProgress,
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import Query from './data/Query';
import { crudUpdate } from '../actions';
import ShowActions from './detail/ShowActions';
import EditActions from './detail/EditActions';
import ReferenceManyFieldLoadingWrapper from './fields/ReferenceManyFieldLoadingWrapper';
import SelectInput from './inputs/SelectInput';
import { Typography, Card, CardContent, withStyles } from '@material-ui/core';
import ReplicateButton from './button/ReplicateButton';
import { PropsExposer } from '../utils';
import LongTextField from './fields/LongTextField';
import { FormDataConsumer } from 'ra-core';
import MultiLangBlock from './other/MultiLangBlock';
import { QUERY_PER_PAGE } from '../constants';

const styles = {
  hotelsCard: {
    width: '80%',
    marginTop: 40,
    boxShadow:
      '0px 1px 6px 0px rgba(0, 0, 0, 0.4), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  },
};

const TITLE_SINGULAR = 'Tour Day';
// const TITLE_PLURAL = 'Tour Days';

const TourDayTitle = props => {
  return (
    <ReferenceFieldController source="tourId" reference="tour" linkType={false} {...props}>
      {({ isLoading, referenceRecord }) =>
        !isLoading && (
          <span>
            {TITLE_SINGULAR} {props.record.number} - {referenceRecord.name}
          </span>
        )
      }
    </ReferenceFieldController>
  );
};
TourDayTitle.propTypes = { record: PropTypes.shape({ number: PropTypes.number }) };

const ReplicateHotelButton = ({ record, data }) => (
  <ReplicateButton
    sourceField={'linkHotel' + record.id}
    destFields={Object.keys(data).map(linkId => 'linkHotel' + linkId)}
  />
);
ReplicateHotelButton.propTypes = {
  record: PropTypes.shape({ id: PropTypes.number.isRequired }),
  data: PropTypes.object.isRequired,
};

const HotelInput = ({ regionHotelIds, ...restProps }) => {
  const currentHotelId = restProps.record.hotelId;
  const validHotelIds = [currentHotelId, ...regionHotelIds];
  return (
    // TODO don't use QUERY_PER_PAGE, improve query
    <ReferenceInput
      perPage={QUERY_PER_PAGE}
      source="hotelId"
      reference="hotel"
      resettable
      {...restProps}
    >
      <PropsExposer>
        {refInputProps => {
          const filteredChoices = refInputProps.choices.filter(
            hotel => validHotelIds.indexOf(hotel.id) !== -1,
          );
          return (
            <SelectInput
              optionText="name"
              options={{ label: null }}
              {...refInputProps}
              choices={filteredChoices}
            />
          );
        }}
      </PropsExposer>
    </ReferenceInput>
  );
};

const HotelInputWithField = props => (
  <Field name={'linkHotel' + props.record.id} component={HotelInput} {...props} />
);
HotelInputWithField.propTypes = {
  record: PropTypes.shape({ id: PropTypes.number.isRequired }),
};

const HotelsSection = withStyles(styles)(({ edit, classes, ...restProps }) => (
  <Query
    onlyData
    type="GET_ONE"
    resource="tour"
    payload={{ id: restProps.record.tourId }}
    errorMsg="Failed to fetch hotels."
  >
    {tour => (
      <Query
        onlyData
        type="GET_ALL"
        resource="hotelToRegionLink"
        errorMsg="Failed to fetch hotels."
      >
        {hotelToRegionLinks => {
          const regionHotelIds = hotelToRegionLinks
            .filter(link => link.regionId === tour.regionId)
            .map(link => link.hotelId);

          return (
            <Card className={classes.hotelsCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Participant hotels
                </Typography>
                <ReferenceManyField
                  reference="participantToTourDayLink"
                  target="tourDayId"
                  sort={{ field: 'participant.name', order: 'ASC' }}
                  {...restProps}
                >
                  <ReferenceManyFieldLoadingWrapper loadingText="This tour has no participants yet.">
                    <PropsExposer>
                      {refManyProps => (
                        <Datagrid {...refManyProps}>
                          <ReferenceField source="participantId" reference="participant">
                            <TextField source="name" />
                          </ReferenceField>
                          {edit && (
                            <HotelInputWithField label="Hotel" regionHotelIds={regionHotelIds} />
                          )}
                          {edit && <ReplicateHotelButton data={refManyProps.data} />}
                          {!edit && (
                            <ReferenceField source="hotelId" reference="hotel" allowEmpty>
                              <TextField source="name" />
                            </ReferenceField>
                          )}
                        </Datagrid>
                      )}
                    </PropsExposer>
                  </ReferenceManyFieldLoadingWrapper>
                </ReferenceManyField>
              </CardContent>
            </Card>
          );
        }}
      </Query>
    )}
  </Query>
));
HotelsSection.propTypes = {
  edit: PropTypes.bool,
};
HotelsSection.displayName = 'HotelsSection';

export const TourDayShow = props => (
  <Show title={<TourDayTitle {...props} />} actions={<ShowActions />} {...props}>
    <SimpleShowLayout>
      <NumberField source="number" />
      <ReferenceField source="tourId" reference="tour">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="routeId" reference="route" allowEmpty>
        <TextField source="internalName" />
      </ReferenceField>
      <BooleanField source="hasBreakfast" />
      <BooleanField source="hasLunch" />
      <BooleanField source="hasDinner" />
      <MultiLangBlock isShow>
        <LongTextField source="descriptionRouteBook" label="Description for route book" />
      </MultiLangBlock>
      <MultiLangBlock isShow>
        <LongTextField source="descriptionApp" label="Description for app" />
      </MultiLangBlock>
      <ReferenceField source="transferId" reference="transfer" allowEmpty>
        <TextField source="companyName" />
      </ReferenceField>
      <HotelsSection />
    </SimpleShowLayout>
  </Show>
);

const TourDayEditToolbar = connect()(props => {
  const { dispatch, ...restProps } = props;
  const { resource, basePath, handleSubmit } = restProps;
  const locationState = props.location.state;
  const redirect = locationState && locationState.redirect;
  const handleSave = () => {
    return handleSubmit(values => {
      const updateValues = {};
      for (const [key, value] of Object.entries(values)) {
        if (key.startsWith('linkHotel')) {
          const linkId = parseInt(key.slice('linkHotel'.length));
          dispatch(
            crudUpdate(
              'participantToTourDayLink',
              linkId,
              { hotelId: value, tourDayId: values.id },
              null,
              null,
              null,
              { successNotification: null },
            ),
          );
        } else {
          updateValues[key] = value;
        }
      }
      dispatch(crudUpdate(resource, values.id, updateValues, null, basePath, redirect));
    });
  };
  return (
    <Toolbar {...restProps}>
      <SaveButton redirect={redirect} handleSubmitWithRedirect={handleSave} />
    </Toolbar>
  );
});
TourDayEditToolbar.propTypes = {
  resource: PropTypes.string,
  basePath: PropTypes.string,
  handleSubmit: PropTypes.func,
  redirect: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirect: PropTypes.string,
    }),
  }),
};

TourDayEditToolbar.displayName = 'TourDayEditToolbar';

export const TourDayEdit = ({ ...props }) => (
  <Query
    type="GET_MANY_REFERENCE"
    resource="participantToTourDayLink"
    payload={{
      target: 'tourDayId',
      id: props.id,
      pagination: { perPage: 100 },
      sort: { field: 'id', order: 'asc' },
    }}
  >
    {({ data, loading, error }) => {
      if (loading) {
        return <LinearProgress />;
      }
      if (error) {
        return (
          <Typography color="error" gutterBottom>
            Failed to fetch hotels data.
          </Typography>
        );
      }
      return (
        <Edit title={<TourDayTitle {...props} />} actions={<EditActions />} {...props}>
          <SimpleForm
            toolbar={<TourDayEditToolbar location={props.location} />}
            defaultValue={data.reduce(
              (defaults, link) => ({ ...defaults, ['linkHotel' + link.id]: link.hotelId }),
              {},
            )}
          >
            <DisabledInput source="number" validate={[required()]} />
            <ReferenceInput
              perPage={QUERY_PER_PAGE}
              source="tourId"
              reference="tour"
              validate={[required()]}
            >
              <SelectInput optionText="name" disabled />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData: { tourId } }) => (
                <Query
                  onlyData
                  type="GET_ONE"
                  resource="tour"
                  payload={{ id: tourId }}
                  errorMsg="Failed to fetch tour."
                >
                  {tour => (
                    <Query
                      onlyData
                      type="GET_ALL"
                      resource="routeToRegionLink"
                      payload={{ filter: { regionId: tour.regionId } }}
                      errorMsg="Failed to fetch tour."
                    >
                      {routeToRegionLinks => (
                        <ReferenceInput
                          source="routeId"
                          reference="route"
                          filter={{ id: { _in: routeToRegionLinks.map(link => link.routeId) } }}
                          resettable
                          resource="tourDay"
                          perPage={200}
                        >
                          <SelectInput optionText="internalName" />
                        </ReferenceInput>
                      )}
                    </Query>
                  )}
                </Query>
              )}
            </FormDataConsumer>
            <BooleanInput source="hasBreakfast" />
            <BooleanInput source="hasLunch" />
            <BooleanInput source="hasDinner" />
            <MultiLangBlock>
              <LongTextInput source="descriptionRouteBook" label="Description for route book" />
            </MultiLangBlock>
            <MultiLangBlock>
              <LongTextInput source="descriptionApp" label="Description for app" />
            </MultiLangBlock>
            <ReferenceInput
              perPage={QUERY_PER_PAGE}
              source="transferId"
              reference="transfer"
              resettable
            >
              <SelectInput optionText="companyName" />
            </ReferenceInput>
            <HotelsSection edit />
          </SimpleForm>
        </Edit>
      );
    }}
  </Query>
);
TourDayEdit.propTypes = {
  location: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};
