import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const LongTextField = ({ record = {}, source }) => {
  const value = get(record, source);

  return <div>{value && value.split('\n').map((text, i) => <p key={i}>{text}</p>)}</div>;
};

LongTextField.defaultProps = {
  addLabel: true,
};

LongTextField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default LongTextField;
