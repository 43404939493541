import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  ImageField,
  List,
  LongTextInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  required,
  Filter
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import CurrencyField from './fields/CurrencyField';
import CurrencyInput from './inputs/CurrencyInput';
import LongTextField from './fields/LongTextField';
import LLRUploadInput from './inputs/LLRUploadInput';
import MultiLangBlock from './other/MultiLangBlock';

const TITLE_SINGULAR = 'Bike Model';
const TITLE_PLURAL = 'Bike Models';

const BikeModelTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
BikeModelTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const BikeModelFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__name" alwaysOn />
  </Filter>
);

export const BikeModelList = props => (
  <List title={TITLE_PLURAL} {...props} sort={{ field: 'name', order: 'ASC' }} filters={<BikeModelFilters />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <CurrencyField source="dailyCost" />
      <ImageField source="imageUrl" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const BikeModelShow = props => (
  <Show title={<BikeModelTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <MultiLangBlock isShow>
        <LongTextField source="description" label="Description" />
      </MultiLangBlock>
      <CurrencyField source="dailyCost" />
      <ImageField source="imageUrl" label="Image" />
      <ReferenceManyField label="Bikes" reference="bike" target="modelId">
        <SingleFieldList>
          <ChipField source="number" />
        </SingleFieldList>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export const BikeModelEdit = props => (
  <Edit title={<BikeModelTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <MultiLangBlock>
        <LongTextInput source="description" label="Description" validate={[required()]} />
      </MultiLangBlock>
      <CurrencyInput source="dailyCost" />
      <LLRUploadInput
        source="imageUrl"
        type="image"
        label="Image (810x540)"
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);

export const BikeModelCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} autoFocus />
      <MultiLangBlock>
        <LongTextInput source="description" label="Description" validate={[required()]} />
      </MultiLangBlock>
      <CurrencyInput source="dailyCost" />
      <LLRUploadInput
        source="imageUrl"
        type="image"
        label="Image (810x540)"
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);
