import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const CurrencyField = ({ record = {}, source, currency = '€' }) => {
  const value = get(record, source);
  return <span>{`${ (value / 100).toFixed(2) }${ currency }`}</span>;
};

CurrencyField.defaultProps = {
  addLabel: true,
};

CurrencyField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  currency: PropTypes.string,
};

export default CurrencyField;
