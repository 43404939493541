import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  LongTextInput,
  ImageField,
  EmailField,
  required,
  Filter,
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import LongTextField from './fields/LongTextField';
import SelectInput from './inputs/SelectInput';
import LLRUploadInput from './inputs/LLRUploadInput';
import ManyToManyInputHelper from './data/ManyToManyInputHelper';
import { isCoordinates } from '../validators';
import ReferenceManyToManyField from './data/ReferenceManyToManyField';
import MultiLangBlock from './other/MultiLangBlock';
import { QUERY_PER_PAGE } from '../constants';

const TITLE_SINGULAR = 'Point of Interest';
const TITLE_PLURAL = 'Points of Interest';

function requiredIfNotNavigationAlert(value, record) {
  // CategoryId 5 is navigationAlert
  if (record.categoryId !== 5 && !value) {
    return 'Required';
  }
}
requiredIfNotNavigationAlert.isRequired = true;

const PointOfInterestTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
PointOfInterestTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const PointOfInterestFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__name_address" alwaysOn />
    <ReferenceInput perPage={QUERY_PER_PAGE} source="categoryId" reference="poiCategory" alwaysOn>
      <SelectInput label="Category" source="categoryId" />
    </ReferenceInput>
  </Filter>
);

export const PointOfInterestList = props => (
  <List
    title={TITLE_PLURAL}
    {...props}
    filters={<PointOfInterestFilters />}
    sort={{ field: 'categoryId', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="address" />
      <ReferenceField source="categoryId" reference="poiCategory">
        <TextField source="name" />
      </ReferenceField>

      <ShowButton />
    </Datagrid>
  </List>
);

export const PointOfInterestShow = props => (
  <Show title={<PointOfInterestTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="address" />
      <TextField source="coordinates" />
      <ImageField source="imageUrl" />
      <ReferenceField source="categoryId" reference="poiCategory">
        <TextField source="name" />
      </ReferenceField>
      <MultiLangBlock isShow>
        <LongTextField source="description" label="Description" />
      </MultiLangBlock>
      <ReferenceManyToManyField
        linkResName="poiToRegionLink"
        fromResIdName="pointOfInterestId"
        toResIdName="regionId"
        toResName="region"
        label="Regions"
      />
    </SimpleShowLayout>
  </Show>
);

export const PointOfInterestEdit = props => (
  <Edit title={<PointOfInterestTitle />} {...props}>
    <ManyToManyInputHelper
      configs={[
        {
          source: 'regions',
          linkResName: 'poiToRegionLink',
          fromResIdName: 'pointOfInterestId',
          toResIdName: 'regionId',
          toResName: 'region',
          label: 'Regions',
        },
      ]}
    >
      {(editProps, defaultValue, [RegionsInput]) => (
        <SimpleForm defaultValue={defaultValue} {...editProps}>
          <TextInput source="name" validate={[required()]} />
          <TextInput source="email" />
          <TextInput source="phoneNumber" />
          <TextInput source="address" validate={[requiredIfNotNavigationAlert]} />
          <TextInput source="coordinates" validate={[required(), isCoordinates()]} />
          <LLRUploadInput
            source="imageUrl"
            type="image"
            label="Image (720x560)"
            validate={[requiredIfNotNavigationAlert]}
          />
          <ReferenceInput
            perPage={QUERY_PER_PAGE}
            source="categoryId"
            reference="poiCategory"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <MultiLangBlock>
            <LongTextInput source="description" label="Description" validate={[required()]} />
          </MultiLangBlock>
          <RegionsInput />
        </SimpleForm>
      )}
    </ManyToManyInputHelper>
  </Edit>
);

export const PointOfInterestCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} autoFocus />
      <TextInput source="email" />
      <TextInput source="phoneNumber" />
      <TextInput source="address" validate={[requiredIfNotNavigationAlert]} />
      <TextInput source="coordinates" validate={[required(), isCoordinates()]} />
      <LLRUploadInput
        source="imageUrl"
        type="image"
        label="Image (720x560)"
        validate={[requiredIfNotNavigationAlert]}
      />
      <ReferenceInput
        perPage={QUERY_PER_PAGE}
        source="categoryId"
        reference="poiCategory"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <MultiLangBlock>
        <LongTextInput source="description" label="Description" validate={[required()]} />
      </MultiLangBlock>
    </SimpleForm>
  </Create>
);
