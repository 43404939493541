import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinearProgress, Typography } from '@material-ui/core';
import { withDataProvider, showNotification } from 'react-admin';
import * as Sentry from '@sentry/browser';
import { QUERY_PER_PAGE } from '../../constants';

class Query extends React.Component {
  state = {
    data: null,
    total: null,
    loading: true,
    error: null,
  };

  componentDidMount = () => {
    const {
      dataProvider,
      type,
      resource,
      payload,
      options,
      onSuccess,
      onFailure,
      dispatch,
    } = this.props;
    let finalPayload = payload || {};
    let finalType = type;
    if (type === 'GET_ALL') {
      finalType = 'GET_LIST';
      finalPayload = {
        // TODO don't use QUERY_PER_PAGE, improve query
        pagination: { perPage: QUERY_PER_PAGE },
        sort: { field: 'id', order: 'asc' },
        ...finalPayload,
      };
    }
    dataProvider(finalType, resource, finalPayload, options)
      .then(({ data, total }) => {
        this.setState({
          data,
          total,
          loading: false,
        });
        if (onSuccess) {
          onSuccess({ data, total });
        }
        return { data, total };
      })
      .catch(error => {
        this.setState({
          error,
          loading: false,
        });
        if (onFailure) {
          onFailure(error);
        } else {
          dispatch(showNotification('Unknown error', 'warning'));
          console.error(error);
          Sentry.captureException(error);
        }
      });
  };

  render () {
    const { children, onlyData, errorMsg } = this.props;
    if (onlyData && !errorMsg) {
      throw new Error('Using onlyData without setting errorMsg.');
    }
    if (onlyData) {
      if (this.state.loading) {
        return <LinearProgress />;
      }
      if (this.state.error) {
        return (
          <Typography color="error" gutterBottom>
            {errorMsg}
          </Typography>
        );
      }
      return children(this.state.data);
    } else {
      return children(this.state);
    }
  }
}

Query.propTypes = {
  children: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  onlyData: PropTypes.bool,
  dataProvider: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  payload: PropTypes.object,
  options: PropTypes.object,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(withDataProvider(Query));
