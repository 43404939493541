import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  List,
  ReferenceInput,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  ImageField,
  NumberField,
  NumberInput,
  LongTextInput,
  SimpleShowLayout,
  Filter,
  required,
  UrlField
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import { FormHelperText, withStyles } from '@material-ui/core';
import Query from './data/Query';
import ShowButton from './button/ShowButton';
import ShowActions from './detail/ShowActions';
import EditActions from './detail/EditActions';
import LongTextField from './fields/LongTextField';
import LLRUploadInput from './inputs/LLRUploadInput';
import SelectInput from './inputs/SelectInput';
import SelectArrayInput from './inputs/SelectArrayInput';
import { getChoices, ellipsize } from '../utils';
import ManyToManyInputHelper from './data/ManyToManyInputHelper';
import ReferenceManyToManyField from './data/ReferenceManyToManyField';
import { FormDataConsumer } from 'ra-core';
import MultiLangBlock from './other/MultiLangBlock';
import { QUERY_PER_PAGE } from '../constants';

const styles = {
  multiSelect: {
    minWidth: '256px',
    maxWidth: '512px',
  },
};

const TITLE_SINGULAR = 'Route';
const TITLE_PLURAL = 'Routes';

const RouteTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
RouteTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const RouteFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__internalName_name" alwaysOn />
    <ReferenceInput
      perPage={QUERY_PER_PAGE}
      source="regionId"
      reference="region"
      label="Region"
      alwaysOn
    >
      <SelectInput />
    </ReferenceInput>
  </Filter>
);

export const RouteList = props => (
  <List
    title={TITLE_PLURAL}
    {...props}
    filters={<RouteFilters />}
    sort={{ field: 'internalName', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="internalName" />
      <ReferenceManyToManyField
        linkResName="routeToRegionLink"
        fromResIdName="routeId"
        toResIdName="regionId"
        toResName="region"
        label="Regions"
      />
      <ImageField source="imageUrl" label="Photo" />
      <ImageField source="mapUrl" label="Map" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const RouteShow = props => (
  <Show title={<RouteTitle />} actions={<ShowActions />} {...props}>
    <SimpleShowLayout>
      <UrlField source="gpxUrl" />
      <TextField source="internalName" />
      <TextField source="name" />

      <ImageField source="mapUrl" label="Map" />
      <ImageField source="imageUrl" label="Photo" />
      <ImageField source="altimetryUrl" label="Altimetry image" />
      <NumberField source="distanceKms" />
      <NumberField source="altimetry" label="Altimetry meters" />
      <MultiLangBlock isShow>
        <LongTextField source="description" label="Description" />
      </MultiLangBlock>
      <ReferenceManyToManyField
        linkResName="routeToRegionLink"
        fromResIdName="routeId"
        toResIdName="regionId"
        toResName="region"
        label="Regions"
      />
      <ReferenceManyToManyField
        linkResName="poiToRouteLink"
        fromResIdName="routeId"
        toResIdName="pointOfInterestId"
        toResName="pointOfInterest"
        label="Points of interest"
      />
      <ReferenceManyToManyField
        linkResName="bikeShopToRouteLink"
        fromResIdName="routeId"
        toResIdName="bikeShopId"
        toResName="bikeShop"
        label="Bike shops"
      />
    </SimpleShowLayout>
  </Show>
);
RouteShow.propTypes = {
  location: PropTypes.object.isRequired,
};

const PointsOfInterestSelectField = ({ record, isChip }) => (
  <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '0.95rem' }}>
    <span>{record.name}</span>
    {!isChip && record.address && (
      <span style={{ color: '#aaa', fontSize: 10, lineHeight: '0.95rem' }}>
        {ellipsize(record.address, 50)}
      </span>
    )}
  </div>
);

const PointsOfInterestInput = withStyles(styles)(({ classes }) => (
  <FormDataConsumer>
    {({ formData }) =>
      formData && (
        <Query
          onlyData
          type="GET_ALL"
          resource="pointOfInterest"
          errorMsg="Failed to fetch points of interest."
        >
          {pois => (
            <Query
              onlyData
              type="GET_ALL"
              resource="poiToRegionLink"
              errorMsg="Failed to fetch points of interests."
            >
              {poiToRegionLinks => (
                <Query
                  onlyData
                  type="GET_ALL"
                  resource="poiCategory"
                  errorMsg="Failed to fetch points of interests."
                >
                  {categories => {
                    const regionIdsByPoiId = _.mapValues(
                      _.groupBy(poiToRegionLinks, 'pointOfInterestId'),
                      links => links.map(link => link.regionId)
                    );
                    const filteredPois = pois.filter(
                      poi =>
                        formData.pois.indexOf(poi.id) !== -1 ||
                        (regionIdsByPoiId[poi.id] &&
                          formData.regions &&
                          regionIdsByPoiId[poi.id].filter(regionId =>
                            formData.regions.includes(regionId)
                          ).length >= 1)
                    );
                    const choicesWithCategories = getChoices(
                      filteredPois,
                      categories,
                      'categoryId',
                      {
                        type: 'grouped',
                        sortBy: ['categoryId', 'name'],
                        extraFields: ['address'],
                      }
                    );

                    return (
                      <SelectArrayInput
                        className={classes.multiSelect}
                        label="Points of interest"
                        source="pois"
                        choices={choicesWithCategories}
                        optionText={<PointsOfInterestSelectField />}
                      />
                    );
                  }}
                </Query>
              )}
            </Query>
          )}
        </Query>
      )
    }
  </FormDataConsumer>
));

const BikeShopsInput = withStyles(styles)(({ classes }) => (
  <FormDataConsumer>
    {({ formData }) =>
      formData && (
        <Query onlyData type="GET_ALL" resource="bikeShop" errorMsg="Failed to fetch bike shop.">
          {bikeShops => (
            <Query
              onlyData
              type="GET_ALL"
              resource="bikeShopToRegionLink"
              errorMsg="Failed to fetch bike shops."
            >
              {bikeShopToRegionLinks => {
                const regionIdsByBikeShopId = _.mapValues(
                  _.groupBy(bikeShopToRegionLinks, 'bikeShopId'),
                  links => links.map(link => link.regionId)
                );
                const filteredBikeShops = bikeShops.filter(
                  bikeShop =>
                    formData.bikeShops.indexOf(bikeShop.id) !== -1 ||
                    (regionIdsByBikeShopId[bikeShop.id] &&
                      formData.regions &&
                      regionIdsByBikeShopId[bikeShop.id].filter(regionId =>
                        formData.regions.includes(regionId)
                      ).length >= 1)
                );

                return (
                  <SelectArrayInput
                    className={classes.multiSelect}
                    label="Bike Shops"
                    source="bikeShops"
                    choices={filteredBikeShops}
                  />
                );
              }}
            </Query>
          )}
        </Query>
      )
    }
  </FormDataConsumer>
));

export const RouteEdit = props => (
  <Edit title={<RouteTitle />} actions={<EditActions />} {...props}>
    <ManyToManyInputHelper
      configs={[
        {
          source: 'regions',
          linkResName: 'routeToRegionLink',
          fromResIdName: 'routeId',
          toResIdName: 'regionId',
          toResName: 'region',
          label: 'Regions',
        },
        {
          source: 'pois',
          linkResName: 'poiToRouteLink',
          fromResIdName: 'routeId',
          toResIdName: 'pointOfInterestId',
          toResName: 'pointOfInterest',
          label: 'Points of interest',
        },
        {
          source: 'bikeShops',
          linkResName: 'bikeShopToRouteLink',
          fromResIdName: 'routeId',
          toResIdName: 'bikeShopId',
          toResName: 'bikeShop',
          label: 'Bike Shops',
        },
      ]}
    >
      {(editProps, defaultValue, [RegionsInput]) => (
        <SimpleForm defaultValue={defaultValue} {...editProps}>
          <LLRUploadInput
            source="gpxUrl"
            label="GPX file"
            type="file"
            extension="gpx"
            validate={[required()]}
          />
          <>
            <FormHelperText disabled>
              NOTE: Uploading a GPX file will override Name, Map, Distance Kms and Altimetry fields.
            </FormHelperText>
          </>
          <TextInput source="internalName" validate={[required()]} />
          <TextInput source="name" validate={[required()]} />
          <LLRUploadInput source="mapUrl" label="Map" type="image" validate={[required()]} />
          <LLRUploadInput
            source="altimetryUrl"
            label="Altimetry image"
            type="image"
            validate={[required()]}
          />
          <NumberInput source="distanceKms" validate={[required()]} />
          <NumberInput source="altimetry" label="Altimetry meters" validate={[required()]} />
          <LLRUploadInput
            source="imageUrl"
            label="Image (720x560)"
            type="image"
            validate={[required()]}
          />
          <MultiLangBlock>
            <LongTextInput source="description" label="Description" validate={[required()]} />
          </MultiLangBlock>
          <RegionsInput />
          <PointsOfInterestInput />
          <BikeShopsInput />
        </SimpleForm>
      )}
    </ManyToManyInputHelper>
  </Edit>
);
RouteEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export const RouteCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <LLRUploadInput
        source="gpxUrl"
        label="GPX file"
        type="file"
        extension="gpx"
        validate={[required()]}
      />
      <TextInput source="internalName" validate={[required()]} />
      <TextInput source="name" validate={[required()]} />
      <LLRUploadInput source="mapUrl" label="Map" type="image" validate={[required()]} />
      <LLRUploadInput
        source="altimetryUrl"
        label="Altimetry image"
        type="image"
        validate={[required()]}
      />
      <NumberInput source="distanceKms" validate={[required()]} />
      <NumberInput source="altimetry" label="Altimetry meters" validate={[required()]} />
      <LLRUploadInput
        source="imageUrl"
        type="image"
        label="Image (720x560)"
        validate={[required()]}
      />
      <MultiLangBlock>
        <LongTextInput source="description" label="Description" validate={[required()]} />
      </MultiLangBlock>
    </SimpleForm>
  </Create>
);
