import React from 'react';
import PropTypes from 'prop-types';
import { addField, FormInput, FieldTitle } from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from 'material-ui-pickers';

const styles = theme =>
  createStyles({
    input: {
      marginTop: 2 * theme.spacing.unit,
    },
  });

const DateInput = ({ source, resource, input, label, meta, isRequired, classes }) => {
  if (typeof meta === 'undefined') {
    throw new Error(
      "The DateInput component wasn't called within a redux-form <Field>. " +
        'Did you decorate it and forget to add the addField prop to your component? ' +
        'See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component ' +
        'for details.'
    );
  }

  const { value, onChange } = input;
  const { touched, error } = meta;
  const hasError = Boolean(touched && error);
  return (
    <div>
      <FormInput
        input={
          <FormControl margin="normal">
            <InputLabel shrink error={hasError} htmlFor={source}>
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            </InputLabel>
            <DatePicker
              format="DD/MM/YYYY"
              value={value || null}
              onChange={m => onChange(m && m.format('YYYY-MM-DD'))}
              id={source}
              className={classes.input}
              allowKeyboardControl
              clearable
            />
          </FormControl>
        }
      />

      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </div>
  );
};

DateInput.defaultProps = {
  addLabel: true,
};

DateInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  isRequired: PropTypes.bool,
  classes: PropTypes.object,
};

export default addField(withStyles(styles)(DateInput));
