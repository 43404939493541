
export const HASURA_SERVER = 'https://llr-hasura-prod.herokuapp.com/';
export const HASURA_QUERY_ENDPOINT = 'https://llr-hasura-prod.herokuapp.com/v1/graphql';

export const SENTRY_DNS = 'https://3b8640a9fb2f418582aab647595bc24e@sentry.io/1469890';

export const ANONYMOUS_JSON_WEB_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhbm9ueW1vdXMiLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiYW5vbnltb3VzIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6ImFub255bW91cyJ9LCJpYXQiOjE1NTk4NTM3NTUsImV4cCI6MzMxMTc0NTM3NTV9.zm4usN9E_GI3pqIH0lUhJ5ockDqlLLTIPJFOJNv--vM';

export const LANGUAGES = [
  {
    code: 'en',
    sourceSuffix: '',
    label: 'EN',
    keepRequired: true,
  },
  {
    code: 'pt',
    sourceSuffix: '_pt',
    label: 'PT',
    keepRequired: false,
  },
];

export const QUERY_PER_PAGE = 5000;
