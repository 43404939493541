import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  LongTextInput,
  ImageField,
  required,
  Filter
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import LongTextField from './fields/LongTextField';
import LLRUploadInput from './inputs/LLRUploadInput';
import ManyToManyInputHelper from './data/ManyToManyInputHelper';
import ReferenceManyToManyField from './data/ReferenceManyToManyField';
import MultiLangBlock from './other/MultiLangBlock';

const TITLE_SINGULAR = 'Region';
const TITLE_PLURAL = 'Regions';

const RegionTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
RegionTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const RegionFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__name" alwaysOn />
  </Filter>
);

export const RegionList = props => (
  <List
    title={TITLE_PLURAL}
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    filters={<RegionFilters />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ImageField source="image" />

      <ShowButton />
    </Datagrid>
  </List>
);

export const RegionShow = props => (
  <Show title={<RegionTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ImageField source="image" />
      <MultiLangBlock isShow>
        <LongTextField source="description" label="Description" />
      </MultiLangBlock>
      <ReferenceManyToManyField
        linkResName="routeToRegionLink"
        fromResIdName="regionId"
        toResIdName="routeId"
        toResName="route"
        label="Routes"
      />
      <ReferenceManyToManyField
        linkResName="hotelToRegionLink"
        fromResIdName="regionId"
        toResIdName="hotelId"
        toResName="hotel"
        label="Hotels"
      />
      <ReferenceManyToManyField
        linkResName="bikeShopToRegionLink"
        fromResIdName="regionId"
        toResIdName="bikeShopId"
        toResName="bikeShop"
        label="Bike Shops"
      />
      <ReferenceManyToManyField
        linkResName="poiToRegionLink"
        fromResIdName="regionId"
        toResIdName="pointOfInterestId"
        toResName="pointOfInterest"
        label="Points of interest"
      />
    </SimpleShowLayout>
  </Show>
);

export const RegionEdit = props => (
  <Edit title={<RegionTitle />} {...props}>
    <ManyToManyInputHelper
      configs={[
        {
          source: 'routes',
          linkResName: 'routeToRegionLink',
          fromResIdName: 'regionId',
          toResIdName: 'routeId',
          toResName: 'route',
          label: 'Routes',
        },
        {
          source: 'hotels',
          linkResName: 'hotelToRegionLink',
          fromResIdName: 'regionId',
          toResIdName: 'hotelId',
          toResName: 'hotel',
          label: 'Hotels',
        },
        {
          source: 'bikeShops',
          linkResName: 'bikeShopToRegionLink',
          fromResIdName: 'regionId',
          toResIdName: 'bikeShopId',
          toResName: 'bikeShop',
          label: 'Bike Shops',
        },
        {
          source: 'pois',
          linkResName: 'poiToRegionLink',
          fromResIdName: 'regionId',
          toResIdName: 'pointOfInterestId',
          toResName: 'pointOfInterest',
          label: 'Points of interest',
        },
      ]}
    >
      {(editProps, defaultValue, [HotelsInput, BikeShopsInput, PointsOfInterestInput]) => (
        <SimpleForm defaultValue={defaultValue} {...editProps}>
          <TextInput source="name" validate={[required()]} />
          <LLRUploadInput
            source="image"
            type="image"
            label="Image (720x780)"
            validate={[required()]}
          />
          <MultiLangBlock>
            <LongTextInput source="description" label="Description" validate={[required()]} />
          </MultiLangBlock>
          <HotelsInput />
          <BikeShopsInput />
          <PointsOfInterestInput />
        </SimpleForm>
      )}
    </ManyToManyInputHelper>
  </Edit>
);

export const RegionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} autoFocus />
      <LLRUploadInput source="image" type="image" label="Image (720x780)" validate={[required()]} />
      <MultiLangBlock>
        <LongTextInput source="description" label="Description" validate={[required()]} />
      </MultiLangBlock>
    </SimpleForm>
  </Create>
);
