import _ from 'lodash';

export function ellipsize(string, length = 10) {
  if (string.length > length) {
    return string.substring(0, length) + '...';
  } else {
    return string;
  }
}

export function generatePassword(length = 8) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export async function hashPassword(password) {
  return password + '-';
}

export const getChoices = (
  resList,
  resGroupList,
  groupIdField,
  { type, resNameField = 'name', groupNameField = 'name', extraFields = [], sortBy = [] } = {},
) => {
  const groupMap = _.keyBy(resGroupList, 'id');
  const choices = [];
  let lastGroupId = null;
  for (const res of _.sortBy(resList, sortBy)) {
    const group = groupMap[res[groupIdField]];
    if (type === 'grouped' && group.id !== lastGroupId) {
      lastGroupId = group.id;
      choices.push({
        id: '@@group-' + group.id,
        name: typeof groupNameField === 'function' ? groupNameField(group) : group[groupNameField],
        disabled: true,
      });
    }
    const choice = {
      id: res.id,
      name: typeof resNameField === 'function' ? resNameField(res, group) : res[resNameField],
    };
    for (const extraField of extraFields) {
      choice[extraField] = res[extraField];
    }
    choices.push(choice);
  }
  return choices;
};

export const PropsLogger = ({ x, ...restProps }) => {
  const name = x === undefined ? '' : x;
  console.log(`PropsLogger-${name}: `, restProps);
  return null;
};

export const PropsExposer = ({ children, ...restProps }) => {
  return children(restProps);
};

export function downloadURI(uri, name) {
  const link = window.document.createElement('a');
  link.download = name;
  link.href = uri;
  window.document.body.appendChild(link);
  link.click();
  window.document.body.removeChild(link);
}
