import hasuraDataProvider from './ra-data-hasura';
import { HASURA_SERVER, ANONYMOUS_JSON_WEB_TOKEN } from '../constants';
import { fetchUnusedTourGroupIds } from '../api';

const headers = () => {
  const jsonWebToken = window.localStorage.getItem('jsonWebToken') || ANONYMOUS_JSON_WEB_TOKEN;
  const headers = {
    'content-type': 'application/json',
    Authorization: 'Bearer ' + jsonWebToken,
  };
  return headers;
};

const hasuraDataProviderWithSetup = hasuraDataProvider(HASURA_SERVER, headers);

export default async (type, resource, params, options) => {
  if (type === 'CUSTOM') {
    if (resource === 'fetchUnusedTourGroupIds') {
      return fetchUnusedTourGroupIds();
    }
    throw new Error(`Unknow resource "${ resource }" for type CUSTOM`);
  }
  return hasuraDataProviderWithSetup(type, resource, params, options);
};
