import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  Create,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  DisabledInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  minLength,
} from 'react-admin';
import { withStyles, FormHelperText } from '@material-ui/core';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import { generatePassword } from '../utils';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const TITLE_SINGULAR = 'User';
const TITLE_PLURAL = 'Users';

const UserTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.username}"
  </span>
);
UserTitle.propTypes = { record: PropTypes.shape({ username: PropTypes.string }) };

export const UserList = props => (
  <List title={TITLE_PLURAL} {...props} sort={{ field: 'username', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <TextField source="username" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const UserShow = props => (
  <Show title={<UserTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="role" />
      <TextField source="username" />
    </SimpleShowLayout>
  </Show>
);

const UserEditToolbar = withStyles(toolbarStyles)(({ ...restProps }) => {
  return (
    <Toolbar {...restProps}>
      <SaveButton />
      <DeleteButton undoable={false} />
    </Toolbar>
  );
});
UserEditToolbar.displayName = 'UserEditToolbar';

export const UserEdit = props => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <DisabledInput source="role" validate={[required()]} />
      <TextInput source="username" validate={[required()]} />
    </SimpleForm>
  </Edit>
);

const UserCreateToolbar = withRouter(
  connect()(
    withStyles(toolbarStyles)(props => {
      const { dispatch, history, ...restProps } = props;
      const { handleSubmit } = restProps;
      const handleSave = () => {
        return handleSubmit(async values => {
          await dispatch({ type: 'CREATE_USER', payload: values });
        });
      };
      return (
        <Toolbar {...restProps}>
          <SaveButton handleSubmitWithRedirect={handleSave} />
        </Toolbar>
      );
    }),
  ),
);
UserCreateToolbar.displayName = 'UserCreateToolbar';

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<UserCreateToolbar />}>
      <DisabledInput source="role" defaultValue="admin" validate={[required()]} />
      <TextInput source="username" validate={[required()]} />
      <TextInput
        source="password"
        defaultValue={generatePassword(16)}
        validate={[required(), minLength(8)]}
      />
      <FormHelperText disabled>
        WARNING: Store this password somewhere safe before saving as it will be impossible to
        recover afterwards.
      </FormHelperText>
    </SimpleForm>
  </Create>
);
