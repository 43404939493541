import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-admin';
import { change } from 'redux-form';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import { REDUX_FORM_NAME } from 'ra-core';

const ReplicateButton = ({
  sourceField,
  destFields,
  label = 'Replicate to all',
  values,
  changeField,
}) => {
  const replicate = e => {
    // useful to prevent click bubbling in a datagrid with rowClick
    e.stopPropagation();
    const sourceValue = values[sourceField];
    for (const destField of destFields) {
      changeField(destField, sourceValue);
    }
  };
  return (
    <Button label={label} onClick={replicate}>
      <FormatLineSpacingIcon />
    </Button>
  );
};

ReplicateButton.propTypes = {
  sourceField: PropTypes.string.isRequired,
  destFields: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  label: PropTypes.string,
  values: PropTypes.object,
  changeField: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  values: state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values,
});
const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => dispatch(change(REDUX_FORM_NAME, field, value)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplicateButton);
