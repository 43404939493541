import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  LongTextInput,
  ImageField,
  EmailField,
  UrlField,
  required,
  Filter
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import CurrencyField from './fields/CurrencyField';
import CurrencyInput from './inputs/CurrencyInput';
import LongTextField from './fields/LongTextField';
import LLRUploadInput from './inputs/LLRUploadInput';
import ManyToManyInputHelper from './data/ManyToManyInputHelper';
import { isCoordinates } from '../validators';
import ReferenceManyToManyField from './data/ReferenceManyToManyField';
import MultiLangBlock from './other/MultiLangBlock';

const TITLE_SINGULAR = 'Hotel';
const TITLE_PLURAL = 'Hotels';

const HotelTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
HotelTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const HotelFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__name" alwaysOn />
  </Filter>
);

export const HotelList = props => (
  <List title={TITLE_PLURAL} filters={<HotelFilters />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="phoneNumber" />

      <ShowButton />
    </Datagrid>
  </List>
);

export const HotelShow = props => (
  <Show title={<HotelTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="coordinates" />
      <EmailField source="email" />
      <TextField source="bookingEmail" />
      <TextField source="bookingContactName" />
      <TextField source="phoneNumber" />
      <UrlField source="url" />
      <ImageField source="imageUrl" />
      <MultiLangBlock isShow>
        <LongTextField source="description" label="Description" />
      </MultiLangBlock>
      <CurrencyField source="lowSeasonPricePerNight" />
      <CurrencyField source="highSeasonPricePerNight" />
      <ReferenceManyToManyField
        linkResName="hotelToRegionLink"
        fromResIdName="hotelId"
        toResIdName="regionId"
        toResName="region"
        label="Regions"
      />
    </SimpleShowLayout>
  </Show>
);

export const HotelEdit = props => (
  <Edit title={<HotelTitle />} {...props} sort={{ field: 'name', order: 'ASC' }}>
    <ManyToManyInputHelper
      configs={[
        {
          source: 'regions',
          linkResName: 'hotelToRegionLink',
          fromResIdName: 'hotelId',
          toResIdName: 'regionId',
          toResName: 'region',
          label: 'Regions',
        },
      ]}
    >
      {(editProps, defaultValue, [RegionsInput]) => (
        <SimpleForm defaultValue={defaultValue} {...editProps}>
          <TextInput source="name" validate={[required()]} />
          <TextInput source="address" validate={[required()]} />
          <TextInput source="coordinates" validate={[required(), isCoordinates()]} />
          <TextInput source="email" validate={[required()]} />
          <TextInput source="bookingEmail" validate={[required()]} />
          <TextInput source="bookingContactName" validate={[required()]} />
          <TextInput source="phoneNumber" validate={[required()]} />
          <TextInput source="url" />
          <LLRUploadInput
            source="imageUrl"
            type="image"
            label="Image (720x560)"
            validate={[required()]}
          />
          <MultiLangBlock>
            <LongTextInput source="description" label="Description" />
          </MultiLangBlock>
          <CurrencyInput source="lowSeasonPricePerNight" />
          <CurrencyInput source="highSeasonPricePerNight" />
          <RegionsInput />
        </SimpleForm>
      )}
    </ManyToManyInputHelper>
  </Edit>
);

export const HotelCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} autoFocus />
      <TextInput source="address" validate={[required()]} />
      <TextInput source="coordinates" validate={[required(), isCoordinates()]} />
      <TextInput source="email" validate={[required()]} />
      <TextInput source="bookingEmail" validate={[required()]} />
      <TextInput source="bookingContactName" validate={[required()]} />
      <TextInput source="phoneNumber" validate={[required()]} />
      <TextInput source="url" />
      <LLRUploadInput
        source="imageUrl"
        type="image"
        label="Image (720x560)"
        validate={[required()]}
      />
      <MultiLangBlock>
        <LongTextInput source="description" label="Description" />
      </MultiLangBlock>
      <CurrencyInput source="lowSeasonPricePerNight" />
      <CurrencyInput source="highSeasonPricePerNight" />
    </SimpleForm>
  </Create>
);
