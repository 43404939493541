import { FormHelperText, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  BooleanField,
  Button,
  Create,
  DateField,
  DeleteButton,
  Edit,
  EmailField,
  Filter,
  FormInput,
  FormTab,
  Labeled,
  LinearProgress,
  List,
  LongTextInput,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  Show,
  SimpleForm,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  linkToRecord,
  required,
} from 'react-admin';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { crudCreate } from '../actions';
import { generateTagsDocument } from '../api';
import { QUERY_PER_PAGE } from '../constants';
import { withLoading } from '../sagas';
import { downloadURI } from '../utils';
import AddNewButton from './button/AddNewButton';
import DownloadButton from './button/DownloadButton';
import ShowButton from './button/ShowButton';
import Query from './data/Query';
import Datagrid from './detail/Datagrid';
import EditActions from './detail/EditActions';
import ShowActions from './detail/ShowActions';
import ConditionalField from './fields/ConditionalField';
import LongTextField from './fields/LongTextField';
import ReferenceManyFieldLoadingWrapper from './fields/ReferenceManyFieldLoadingWrapper';
import AutocompleteInput from './inputs/AutocompleteInput';
import DateInput from './inputs/DateInput';
import SelectInput from './inputs/SelectInput';
import MultiLangBlock from './other/MultiLangBlock';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const TITLE_SINGULAR = 'Tour';
const TITLE_PLURAL = 'Tours';

const TourTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
TourTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const TourFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__name" alwaysOn />
  </Filter>
);

export const TourList = props => (
  <List
    title={TITLE_PLURAL}
    bulkActions={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<TourFilters />}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceField source="regionId" reference="region">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="tourTypeId" reference="tourType">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startDate" locales="pt-PT" />
      <ShowButton />
    </Datagrid>
  </List>
);

const tourShowStyles = {
  participantsActionsBar: {
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  participantsAction: {
    marginLeft: '16px',
  },
};

export const HotelsAssignedField = props => (
  <Query
    type="GET_MANY_REFERENCE"
    resource="participantToTourDayLink"
    payload={{
      target: 'tourDayId',
      id: props.record.id,
      pagination: { perPage: 100 },
      sort: { field: 'id', order: 'asc' },
    }}
  >
    {({ data, loading, error }) => {
      if (loading) {
        return <LinearProgress />;
      }
      if (error) {
        return (
          <Typography color="error" gutterBottom>
            Failed to fetch hotels data.
          </Typography>
        );
      }
      const totalHotels = data.length;
      const hotelsAssigned = data.filter(link => link.hotelId !== null).length;
      if (hotelsAssigned === totalHotels) {
        return <Typography variant="body1">All</Typography>;
      } else if (hotelsAssigned === 0) {
        return <Typography variant="body1">None</Typography>;
      } else {
        return <Typography variant="body1">Some</Typography>;
      }
    }}
  </Query>
);

HotelsAssignedField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

const editWithRedirect = (location, history, id, basePath) =>
  history.push({
    pathname: linkToRecord(basePath, id),
    state: {
      redirect: location.pathname,
    },
  });

const TourDayTabContent = withRouter(({ location, history, edit, ...restProps }) => (
  <ReferenceManyField
    reference="tourDay"
    target="tourId"
    sort={{ field: 'number', order: 'ASC' }}
    addLabel={false}
    {...restProps}
  >
    <ReferenceManyFieldLoadingWrapper loadingText="Tour has no days.">
      <Datagrid rowClick={(id, basePath) => editWithRedirect(location, history, id, basePath)}>
        <NumberField source="number" />
        <ReferenceField source="routeId" reference="route" allowEmpty>
          <TextField source="internalName" />
        </ReferenceField>
        <ReferenceField source="transferId" reference="transfer" allowEmpty>
          <TextField source="companyName" />
        </ReferenceField>
        <HotelsAssignedField label="Hotels assigned" />
        <BooleanField source="hasBreakfast" />
        <BooleanField source="hasLunch" />
        <BooleanField source="hasDinner" />
        <ShowButton />
      </Datagrid>
    </ReferenceManyFieldLoadingWrapper>
  </ReferenceManyField>
));

const ParticipantTabContent = withRouter(
  connect(null, dispatch => ({
    clearRouteBooks: tourId => dispatch({ type: 'CLEAR_ROUTE_BOOKS', payload: { tourId } }),
    generateRouteBooks: (tourId, forMissingOnly = false) =>
      dispatch({ type: 'GENERATE_ROUTE_BOOKS', payload: { tourId, forMissingOnly } }),
    syncTourParticipants: tourId =>
      dispatch({ type: 'SYNC_TOUR_PARTICIPANTS', payload: { tourId } }),
  }))(props => {
    const [loadingRouteBooks, setLoadingRouteBooks] = useState(false);
    const {
      clearRouteBooks,
      generateRouteBooks,
      syncTourParticipants,
      edit,
      location,
      history,
      ...restProps
    } = props;
    return (
      <>
        {edit && (
          <div style={tourShowStyles.participantsActionsBar}>
            <Button
              label="Download Tags"
              variant="contained"
              onClick={withLoading()(async () => {
                try {
                  const { data: tagsDocumentUrl } = await generateTagsDocument({
                    tourId: parseInt(props.tourId),
                  });
                  downloadURI(tagsDocumentUrl, `tags-tour-${props.tourId}.pdf`);
                } catch (err) {
                  console.error(err);
                  Sentry.captureException(err);
                }
              })}
              style={tourShowStyles.participantsAction}
            >
              <PictureAsPdfIcon />
            </Button>
            {props.record.siteGroupId && (
              <Button
                label="Sync Participants"
                variant="contained"
                onClick={() => syncTourParticipants(parseInt(props.tourId))}
                style={tourShowStyles.participantsAction}
              >
                <PeopleIcon />
              </Button>
            )}
            <Button
              label="Clear All Route Books"
              variant="contained"
              onClick={() => {
                setLoadingRouteBooks(true);
                clearRouteBooks(parseInt(props.tourId));
                // setLoadingRouteBooks(false);
              }}
              style={tourShowStyles.participantsAction}
            >
              <PictureAsPdfIcon />
            </Button>
            <Button
              label="Generate Missing Route Books (max 5)"
              variant="contained"
              onClick={() => {
                setLoadingRouteBooks(true);
                generateRouteBooks(parseInt(props.tourId), true);
                // No need to set loading to false since generateRouteBooks calls refresh
              }}
              style={tourShowStyles.participantsAction}
            >
              <PictureAsPdfIcon />
            </Button>
          </div>
        )}
        <ReferenceManyField reference="participant" target="tourId" addLabel={false} {...restProps}>
          <ReferenceManyFieldLoadingWrapper loadingText="Tour has no participants.">
            <Datagrid
              rowClick={(id, basePath) => editWithRedirect(location, history, id, basePath)}
            >
              <TextField source="name" />
              <EmailField source="email" />
              <TextField source="phoneNumber" />
              <TextField source="accessCode" />
              <ReferenceField source="languageId" reference="language">
                <TextField source="code" />
              </ReferenceField>
              {loadingRouteBooks ? (
                <>
                  <CircularProgress size={20} />
                </>
              ) : (
                <ConditionalField showIf="routeBookUrl" label="Route Book">
                  <DownloadButton source="routeBookUrl" />
                </ConditionalField>
              )}
              <ShowButton />
            </Datagrid>
          </ReferenceManyFieldLoadingWrapper>
        </ReferenceManyField>
        {edit && (
          <AddNewButton
            label="Add new participant"
            createPath="/participant/create"
            parentIdField="tourId"
            redirect={location.pathname}
            {...restProps}
          />
        )}
      </>
    );
  }),
);
ParticipantTabContent.propTypes = {
  tourId: PropTypes.string.isRequired,
  edit: PropTypes.bool,
};
ParticipantTabContent.displayName = 'ParticipantTabContent';

export const TourShow = props => {
  return (
    <Show title={<TourTitle />} actions={<ShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="name" />
          <ReferenceField source="regionId" reference="region">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="startDate" locales="pt-PT" />
          <ReferenceField source="tourTypeId" reference="tourType">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="guide1Id" reference="guide" allowEmpty>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="guide2Id" reference="guide" allowEmpty>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="guide3Id" reference="guide" allowEmpty label="Guide3 (View Only)">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="guide4Id" reference="guide" allowEmpty label="Guide4 (View Only)">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="guide5Id" reference="guide" allowEmpty label="Guide5 (View Only)">
            <TextField source="name" />
          </ReferenceField>
          <LongTextField source="guideNotes" />
          <MultiLangBlock isShow>
            <TextField source="terrainType" label="Terrain type" />
          </MultiLangBlock>
          <TextField source="whatsAppGroupUrl" />
          <TextField source="siteGroupId" />
        </Tab>
        <Tab label="Days" path="days">
          <TourDayTabContent tourId={props.id} />
        </Tab>
        <Tab label="Participants" path="participants">
          <ParticipantTabContent tourId={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
TourShow.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

const TourEditToolbar = withRouter(
  withStyles(toolbarStyles)(({ location, match, staticContext, ...restProps }) => {
    return location.pathname === match.url ? (
      <Toolbar {...restProps}>
        <SaveButton undoable={false} />
        <DeleteButton undoable={false} />
      </Toolbar>
    ) : null;
  }),
);
TourEditToolbar.displayName = 'TourEditToolbar';

export const TourEdit = props => (
  <Edit title={<TourTitle />} actions={<EditActions />} {...props}>
    <TabbedForm toolbar={<TourEditToolbar />}>
      <FormTab label="Main">
        <TextInput source="name" validate={[required()]} />
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="regionId"
          reference="region"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="startDate" validate={[required()]} />
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="tourTypeId"
          reference="tourType"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={QUERY_PER_PAGE} source="guide1Id" reference="guide" resettable>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={QUERY_PER_PAGE} source="guide2Id" reference="guide" resettable>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="guide3Id"
          reference="guide"
          resettable
          label="Guide3 (View Only)"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="guide4Id"
          reference="guide"
          resettable
          label="Guide4 (View Only)"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="guide5Id"
          reference="guide"
          resettable
          label="Guide5 (View Only)"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <LongTextInput source="guideNotes" />
        <MultiLangBlock>
          <TextInput source="terrainType" label="Terrain type" validate={[required()]} />
        </MultiLangBlock>
        <TextInput source="whatsAppGroupUrl" />
        <LongTextInput source="siteGroupId" />
        <FormHelperText disabled>
          WARNING: Changing the siteGroupId might break the syncing participants functionality,
          change with care.
        </FormHelperText>
      </FormTab>
      <FormTab label="Days" path="days">
        <TourDayTabContent tourId={props.id} edit />
      </FormTab>
      <FormTab label="Participants" path="participants">
        <ParticipantTabContent tourId={props.id} edit />
      </FormTab>
    </TabbedForm>
  </Edit>
);
TourEdit.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

const UnusedTourGroupIdsSelect = () => (
  <Query type="CUSTOM" resource="fetchUnusedTourGroupIds">
    {({ data, loading, error }) => {
      if (loading) {
        return (
          <Labeled source="siteGroupId">
            <LinearProgress />
          </Labeled>
        );
      }
      if (error) {
        return (
          <Typography color="error" gutterBottom>
            Failed to fetch site group ids.
          </Typography>
        );
      }
      return (
        <FormInput
          input={
            <AutocompleteInput
              source="siteGroupId"
              allowEmpty
              choices={data.sort().map(siteGroupId => ({ id: siteGroupId }))}
              optionText="id"
              // shouldRenderSuggestions={val => {
              //   return val.trim().length > 2;
              // }}
            />
          }
        />
      );
    }}
  </Query>
);

const TourCreateToolbar = connect()(props => {
  const { dispatch, ...restProps } = props;
  const { resource, basePath, handleSubmit, redirect } = restProps;
  const handleSave = () => {
    return handleSubmit(values => {
      const { duration, ...createValues } = values;
      dispatch(
        crudCreate(resource, createValues, basePath, redirect, {
          onSuccess: ({ payload: { data } }) =>
            dispatch({ type: 'TOUR_CREATED', payload: { data: { ...data, duration } } }),
        }),
      );
    });
  };
  return (
    <Toolbar {...restProps}>
      <SaveButton handleSubmitWithRedirect={handleSave} />
    </Toolbar>
  );
});
TourCreateToolbar.propTypes = {
  resource: PropTypes.string,
  basePath: PropTypes.string,
  handleSubmit: PropTypes.func,
  redirect: PropTypes.string,
};
TourCreateToolbar.displayName = 'TourCreateToolbar';

const durationValidator = value => {
  if (value <= 0 || !Number.isInteger(value)) {
    return 'Please insert a valid number of days.';
  }
};

export const TourCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<TourCreateToolbar />}>
        <UnusedTourGroupIdsSelect />
        <TextInput source="name" validate={[required()]} autoFocus />
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="regionId"
          reference="region"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="startDate" validate={[required()]} />
        <NumberInput source="duration" validate={[durationValidator, required()]} />
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="tourTypeId"
          reference="tourType"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <MultiLangBlock>
          <TextInput source="terrainType" label="Terrain type" validate={[required()]} />
        </MultiLangBlock>
        <ReferenceInput perPage={QUERY_PER_PAGE} source="guide1Id" reference="guide" resettable>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={QUERY_PER_PAGE} source="guide2Id" reference="guide" resettable>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="guide3Id"
          reference="guide"
          resettable
          label="Guide3 (View Only)"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="guide4Id"
          reference="guide"
          resettable
          label="Guide4 (View Only)"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={QUERY_PER_PAGE}
          source="guide5Id"
          reference="guide"
          resettable
          label="Guide5 (View Only)"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <LongTextInput source="guideNotes" />
        <TextInput source="whatsAppGroupUrl" />
      </SimpleForm>
    </Create>
  );
};
