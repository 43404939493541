// in src/comments/commentSaga.js
import * as Sentry from '@sentry/browser';
import { REDUX_FORM_NAME } from 'ra-core';
import { FETCH_CANCEL, FETCH_END, FETCH_START, REFRESH_VIEW, showNotification } from 'react-admin';
import { change } from 'redux-form';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  clearRouteBooks,
  connectParticipantsToTourDays,
  createTourDays,
  createUser,
  fetchRouteDataFromGPX,
  generateRouteBooks,
  syncTourParticipants,
} from './api';
import store from './store';

function* reduxLoggerSaga(enabled) {
  if (enabled) {
    yield takeEvery(
      action =>
        /*! action.type.startsWith('@@redux-form') && */ !action.type.startsWith('@@router'),

      function* (action) {
        yield call(console.log, action);
      },
    );
  }
}

export function withLoading({ refresh = false, redirectTo } = {}) {
  return function (asyncFunc) {
    const fWithLoading = async (...args) => {
      try {
        console.log('FETCH_START');
        store.dispatch({ type: FETCH_START });
        const result = await asyncFunc(...args);
        console.log('FETCH_END');
        store.dispatch({ type: FETCH_END, meta: { redirectTo } });
        if (refresh) {
          console.log('REFRESH_VIEW');
          store.dispatch({ type: REFRESH_VIEW });
        }
        return result;
      } catch (err) {
        console.log('FETCH_CANCEL');
        store.dispatch({ type: FETCH_CANCEL });
        store.dispatch(showNotification('Unknown error', 'warning'));
        console.error(err);
        Sentry.captureException(err);
      }
    };
    Object.defineProperty(fWithLoading, 'name', { value: `withLoading(${asyncFunc.name})` });
    return fWithLoading;
  };
}

const handleOnTourCreated = withLoading({ refresh: true })(async tourValues => {
  console.log('handleOnTourCreated', tourValues);
  if (tourValues.siteGroupId) {
    const { data: createdParticipantsIds } = await syncTourParticipants({ tourId: tourValues.id });
    console.log({ createdParticipantsIds });
  }
  const { data: createdTourDaysIds } = await createTourDays({
    tourId: tourValues.id,
    number: tourValues.duration,
  });
  console.log({ createdTourDaysIds });

  const { data: createdLinksIds } = await connectParticipantsToTourDays({ tourId: tourValues.id });
  console.log({ createdLinksIds });
});

function* onTourCreatedSaga() {
  yield takeEvery('TOUR_CREATED', function* (action) {
    yield call(handleOnTourCreated, action.payload.data);
  });
}

const handleOnParticipantCreated = withLoading({ refresh: true })(async participantValues => {
  console.log('handleOnParticipantCreated', participantValues);
  await connectParticipantsToTourDays({ tourId: participantValues.tourId });
});

function* onParticipantCreatedSaga() {
  yield takeEvery(
    action => action.type === 'RA/CRUD_CREATE_SUCCESS' && action.meta.resource === 'participant',
    function* (action) {
      yield call(handleOnParticipantCreated, action.payload.data);
    },
  );
}

const handleOnTourDayCreated = withLoading({ refresh: true })(async tourDayValues => {
  console.log('handleOnTourDayCreated', tourDayValues.id);
  await connectParticipantsToTourDays({ tourId: tourDayValues.tourId });
});

function* onTourDayCreatedSaga() {
  yield takeEvery(
    action => action.type === 'RA/CRUD_CREATE_SUCCESS' && action.meta.resource === 'tourDay',
    function* (action) {
      yield call(handleOnTourDayCreated, action.payload.data);
    },
  );
}

const handleSyncTourParticipants = withLoading({ refresh: true })(async ({ tourId }) => {
  console.log('handleSyncTourParticipants', tourId);
  const { data: createdParticipantsIds } = await syncTourParticipants({ tourId: tourId });
  console.log({ createdParticipantsIds });

  const { data: createdLinksIds } = await connectParticipantsToTourDays({ tourId: tourId });
  console.log({ createdLinksIds });
});

function* syncTourParticipantsSaga() {
  yield takeEvery('SYNC_TOUR_PARTICIPANTS', function* (action) {
    yield call(handleSyncTourParticipants, action.payload);
  });
}

const handleClearRouteBooks = withLoading({ refresh: true })(async ({ tourId }) => {
  console.log('handleClearRouteBooks', tourId);
  const { data: nParticipantsCleared } = await clearRouteBooks({
    tourId: tourId,
  });
  console.log({ nParticipantsCleared });
});

function* clearRouteBooksSaga() {
  yield takeEvery('CLEAR_ROUTE_BOOKS', function* (action) {
    yield call(handleClearRouteBooks, action.payload);
  });
}

const handleGenerateRouteBooks = withLoading({ refresh: true })(
  async ({ tourId, forMissingOnly }) => {
    console.log('handleGenerateRouteBooks', tourId);
    const { data: routeBookUrls } = await generateRouteBooks({
      tourId: tourId,
      forMissingOnly: forMissingOnly,
    });
    console.log({ routeBookUrls });
  },
);

function* generateRouteBooksSaga() {
  yield takeEvery('GENERATE_ROUTE_BOOKS', function* (action) {
    yield call(handleGenerateRouteBooks, action.payload);
  });
}

const handleGPXUpload = withLoading()(async gpxUrl => {
  console.log('handleGPXUpload', gpxUrl);
  const { data: routeData } = await fetchRouteDataFromGPX(gpxUrl);
  return routeData;
});

function roundToFixedDecimalCases(float, nCases) {
  const mul = Math.pow(10, nCases);
  return Math.round(float * mul) / mul;
}

function* onGPXUploadSaga() {
  yield takeEvery(
    action =>
      action.type === '@@redux-form/CHANGE' && action.meta.field === 'gpxUrl' && action.payload,

    function* (action) {
      const { coordinates, elevations, name, mapUrl, altimetryUrl, distanceKms, elevationGain } =
        yield call(handleGPXUpload, action.payload);
      yield put(change(REDUX_FORM_NAME, 'coordinates', coordinates));
      yield put(change(REDUX_FORM_NAME, 'elevations', elevations));
      yield put(change(REDUX_FORM_NAME, 'internalName', name));
      yield put(change(REDUX_FORM_NAME, 'name', name));
      yield put(change(REDUX_FORM_NAME, 'mapUrl', mapUrl));
      if (altimetryUrl) {
        yield put(change(REDUX_FORM_NAME, 'altimetryUrl', altimetryUrl));
      }
      if (distanceKms) {
        yield put(change(REDUX_FORM_NAME, 'distanceKms', roundToFixedDecimalCases(distanceKms, 2)));
      }
      if (elevationGain) {
        yield put(change(REDUX_FORM_NAME, 'altimetry', roundToFixedDecimalCases(elevationGain, 2)));
      }
    },
  );
}

function* onSiteGroupIdChangeSaga() {
  yield takeEvery(
    action =>
      action.type === '@@redux-form/CHANGE' &&
      action.meta.field === 'siteGroupId' &&
      action.payload,

    function* (action) {
      const state = yield select();
      if (state.router.location.pathname === '/tour/create') {
        yield put(change(REDUX_FORM_NAME, 'name', action.payload));
      }
    },
  );
}

const handleCreateUser = withLoading({ refresh: true, redirectTo: '/user' })(
  async ({ username, password, role }) => {
    console.log('handleCreateUser', { username, password, role });
    const { data: success } = await createUser({ username, password, role });
    console.log({ success });
  },
);

function* createUserSaga() {
  yield takeEvery('CREATE_USER', function* (action) {
    yield call(handleCreateUser, action.payload);
  });
}

export default function* rootSaga() {
  yield all([
    reduxLoggerSaga(false),
    onTourCreatedSaga(),
    onParticipantCreatedSaga(),
    onTourDayCreatedSaga(),
    syncTourParticipantsSaga(),
    clearRouteBooksSaga(),
    generateRouteBooksSaga(),
    onGPXUploadSaga(),
    onSiteGroupIdChangeSaga(),
    createUserSaga(),
  ]);
}
