import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-admin';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const DownloadButton = ({ record, source }) => (
  <Button label="Download" href={record[source]} onClick={stopPropagation}>
    <CloudDownloadIcon />
  </Button>
);

DownloadButton.defaultProps = {
  addLabel: true,
};

DownloadButton.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DownloadButton;
