import { CREATE, UPDATE } from 'react-admin';

export const CRUD_CREATE = 'RA/CRUD_CREATE';
export const crudCreate = (
  resource,
  data,
  basePath,
  redirectTo = 'edit',
  { onSuccess, onFailure, successNotification, failureNotification } = {}
) => ({
  type: CRUD_CREATE,
  payload: { data },
  meta: {
    resource,
    fetch: CREATE,
    onSuccess: {
      notification:
        successNotification !== undefined
          ? successNotification
          : {
            body: 'ra.notification.created',
            level: 'info',
            messageArgs: {
              smart_count: 1,
            },
          },
      redirectTo,
      basePath,
      callback: onSuccess,
    },
    onFailure: {
      notification:
        failureNotification !== undefined
          ? failureNotification
          : {
            body: 'ra.notification.http_error',
            level: 'warning',
          },
      callback: onFailure,
    },
  },
});

export const CRUD_UPDATE = 'RA/CRUD_UPDATE';
export const crudUpdate = (
  resource,
  id,
  data,
  previousData,
  basePath,
  redirectTo = 'show',
  { onSuccess, onFailure, successNotification, failureNotification } = {}
) => ({
  type: CRUD_UPDATE,
  payload: { id, data, previousData },
  meta: {
    resource,
    fetch: UPDATE,
    onSuccess: {
      notification:
        successNotification !== undefined
          ? successNotification
          : {
            body: 'ra.notification.updated',
            level: 'info',
            messageArgs: {
              smart_count: 1,
            },
          },
      redirectTo,
      basePath,
      callback: onSuccess,
    },
    onFailure: {
      notification:
        failureNotification !== undefined
          ? failureNotification
          : {
            body: 'ra.notification.http_error',
            level: 'warning',
          },
      callback: onFailure,
    },
  },
});
