import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { LANGUAGES } from '../../constants';
import { FormHelperText } from '@material-ui/core';

const styles = {
  wrapper: {
    marginTop: 20,
    width: '100%',
  },
  label: {
    marginBottom: 10,
  },
  langElsWrapper: {
    boxSizing: 'border-box',
    paddingLeft: 20,
    marginLeft: 20,
    borderLeft: '1px solid #ccc',
  },
  langElWrapper: {
    marginBottom: 30,
  },
};

const MultiLangBlock = ({ children, isShow, classes, className, ...restProps }) => {
  const { label, source, validate } = children.props;

  return (
    <div className={classes.wrapper}>
      <FormHelperText className={classes.label}>{label}</FormHelperText>
      <div className={classes.langElsWrapper}>
        {LANGUAGES.map((lang, i) => {
          const lastLang = i === LANGUAGES.length - 1;
          return (
            <div key={'lang-' + lang.code} className={isShow && !lastLang && classes.langElWrapper}>
              {isShow && <FormHelperText className={classes.label}>{lang.label}</FormHelperText>}

              {React.cloneElement(children, {
                label: lang.label,
                source: source + lang.sourceSuffix,
                validate: lang.keepRequired ? validate : null,
                style: lastLang ? { paddingBottom: 10 } : null,
                ...restProps,
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
MultiLangBlock.propTypes = {
  children: PropTypes.element.isRequired,
  isShow: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(MultiLangBlock);
