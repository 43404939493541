import React from 'react';
import { Provider } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import JssProvider from 'react-jss/lib/JssProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import {
  BikeModelList,
  BikeModelShow,
  BikeModelEdit,
  BikeModelCreate,
} from './components/bikeModel';
import { BikeList, BikeShow, BikeEdit, BikeCreate } from './components/bike';
import {
  ParticipantList,
  ParticipantShow,
  ParticipantEdit,
  ParticipantCreate,
} from './components/participant';
import { GuideList, GuideShow, GuideEdit, GuideCreate } from './components/guide';
import { BikeShopList, BikeShopShow, BikeShopEdit, BikeShopCreate } from './components/bikeShop';
import { HotelList, HotelShow, HotelEdit, HotelCreate } from './components/hotel';
import {
  PointOfInterestList,
  PointOfInterestShow,
  PointOfInterestEdit,
  PointOfInterestCreate,
} from './components/pointOfInterest';
// import {
//   ConfigurationList,
//   ConfigurationShow,
//   ConfigurationEdit
// } from './components/configuration';
import { RegionList, RegionShow, RegionEdit, RegionCreate } from './components/region';
import { TourDayShow, TourDayEdit } from './components/tourDay';
import { TransferList, TransferShow, TransferEdit, TransferCreate } from './components/transfer';
import { RouteList, RouteShow, RouteEdit, RouteCreate } from './components/route';
import { TourList, TourShow, TourEdit, TourCreate } from './components/tour';
import { UserList, UserShow, UserEdit, UserCreate } from './components/user';
import MapIcon from '@material-ui/icons/Map';
import PeopleIcon from '@material-ui/icons/People';
import GestureIcon from '@material-ui/icons/Gesture';
import HotelIcon from '@material-ui/icons/Hotel';
import PlaceIcon from '@material-ui/icons/Place';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import StoreIcon from '@material-ui/icons/Store';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LandscapeIcon from '@material-ui/icons/Landscape';
// import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import history from './history';
import authProvider from './authProvider';
import store from './store';
import * as Sentry from '@sentry/browser';

import runHacks from './hacks';
import { SENTRY_DNS } from './constants';

runHacks();

if (SENTRY_DNS) {
  Sentry.init({ dsn: SENTRY_DNS });
}

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#2196F3',
    },
  },
  typography: {
    useNextVariants: true,
    htmlFontSize: 17,
  },
});

const App = () => (
  // JssProvider is only used to prevent a crazy bug in production
  // that breaks CSS completely after a refresh
  // See: https://github.com/mui-org/material-ui/issues/8223
  <JssProvider>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <Admin
          title="Backoffice LiveLoveRide"
          theme={theme}
          authProvider={authProvider}
          history={history}
        >
          {permissions => [
            <Resource
              key="tour"
              name="tour"
              options={{ label: 'Tours' }}
              list={TourList}
              show={TourShow}
              edit={TourEdit}
              create={TourCreate}
              icon={MapIcon}
            />,
            <Resource
              key="tourDay"
              name="tourDay"
              show={TourDayShow}
              edit={TourDayEdit}
              icon={undefined}
            />,
            <Resource
              key="participant"
              name="participant"
              options={{ label: 'Participants' }}
              list={ParticipantList}
              show={ParticipantShow}
              edit={ParticipantEdit}
              create={ParticipantCreate}
              icon={PeopleIcon}
            />,
            <Resource
              key="route"
              name="route"
              options={{ label: 'Routes' }}
              list={RouteList}
              show={RouteShow}
              edit={RouteEdit}
              create={RouteCreate}
              icon={GestureIcon}
            />,
            <Resource
              key="hotel"
              name="hotel"
              options={{ label: 'Hotels' }}
              list={HotelList}
              show={HotelShow}
              edit={HotelEdit}
              create={HotelCreate}
              icon={HotelIcon}
            />,
            <Resource
              key="pointOfInterest"
              name="pointOfInterest"
              options={{ label: 'Points of Interest' }}
              list={PointOfInterestList}
              show={PointOfInterestShow}
              edit={PointOfInterestEdit}
              create={PointOfInterestCreate}
              icon={PlaceIcon}
            />,
            <Resource
              key="transfer"
              name="transfer"
              options={{ label: 'Transfers' }}
              list={TransferList}
              show={TransferShow}
              edit={TransferEdit}
              create={TransferCreate}
              icon={LocalTaxiIcon}
            />,
            <Resource
              key="bikeShop"
              name="bikeShop"
              options={{ label: 'Bike Shops' }}
              list={BikeShopList}
              show={BikeShopShow}
              edit={BikeShopEdit}
              create={BikeShopCreate}
              icon={StoreIcon}
            />,
            <Resource
              key="bikeModel"
              name="bikeModel"
              options={{ label: 'Bike Models' }}
              list={BikeModelList}
              show={BikeModelShow}
              edit={BikeModelEdit}
              create={BikeModelCreate}
              icon={MotorcycleIcon}
            />,
            <Resource
              key="bike"
              name="bike"
              options={{ label: 'Bikes' }}
              list={BikeList}
              show={BikeShow}
              edit={BikeEdit}
              create={BikeCreate}
              icon={DirectionsBikeIcon}
            />,
            <Resource
              key="guide"
              name="guide"
              options={{ label: 'Guides' }}
              list={GuideList}
              show={GuideShow}
              edit={GuideEdit}
              create={GuideCreate}
              icon={AccountCircleIcon}
            />,
            <Resource
              key="region"
              name="region"
              options={{ label: 'Regions' }}
              list={RegionList}
              show={RegionShow}
              edit={RegionEdit}
              create={RegionCreate}
              icon={LandscapeIcon}
            />,

            // <Resource
            //   key="configuration"
            //   name="configuration"
            //   options={{ label: 'Configurations' }}
            //   list={ConfigurationList}
            //   show={ConfigurationShow}
            //   edit={ConfigurationEdit}
            //   icon={SettingsIcon}
            // />,

            <Resource
              key="user"
              name="user"
              options={{ label: 'Users' }}
              list={UserList}
              show={UserShow}
              edit={UserEdit}
              create={UserCreate}
              icon={SupervisedUserCircleIcon}
            />,

            <Resource key="participantToTourDayLink" name="participantToTourDayLink" />,
            <Resource key="poiToRouteLink" name="poiToRouteLink" />,
            <Resource key="bikeShopToRouteLink" name="bikeShopToRouteLink" />,
            <Resource key="hotelToRegionLink" name="hotelToRegionLink" />,
            <Resource key="bikeShopToRegionLink" name="bikeShopToRegionLink" />,
            <Resource key="poiToRegionLink" name="poiToRegionLink" />,
            <Resource key="routeToRegionLink" name="routeToRegionLink" />,
            <Resource key="language" name="language" />,
            <Resource key="poiCategory" name="poiCategory" />,
            <Resource key="tourType" name="tourType" />,
          ]}
        </Admin>
      </Provider>
    </MuiPickersUtilsProvider>
  </JssProvider>
);

export default App;
