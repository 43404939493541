import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  DisabledInput,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  LongTextInput,
  ImageField,
  EmailField,
  required,
  Filter
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import CurrencyField from './fields/CurrencyField';
import CurrencyInput from './inputs/CurrencyInput';
import LongTextField from './fields/LongTextField';
import LLRUploadInput from './inputs/LLRUploadInput';
import { generatePassword } from '../utils.js';
import MultiLangBlock from './other/MultiLangBlock';

const TITLE_SINGULAR = 'Guide';
const TITLE_PLURAL = 'Guides';

const GuideTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
GuideTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const GuideFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__name" alwaysOn />
  </Filter>
);

export const GuideList = props => (
  <List
    title={TITLE_PLURAL}
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    filters={<GuideFilters />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="phoneNumber" />
      <TextField source="accessCode" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const GuideShow = props => (
  <Show title={<GuideTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ImageField source="imageUrl" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="accessCode" />
      <MultiLangBlock isShow>
        <LongTextField source="description" label="Description" />
      </MultiLangBlock>
      <CurrencyField source="dailyCost" />
      <CurrencyField source="dailyFoodCost" />
    </SimpleShowLayout>
  </Show>
);

export const GuideEdit = props => (
  <Edit title={<GuideTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <LLRUploadInput
        source="imageUrl"
        type="image"
        label="Image (650x400)"
        validate={[required()]}
      />
      <TextInput source="email" validate={[required()]} />
      <TextInput source="phoneNumber" validate={[required()]} />
      <MultiLangBlock>
        <LongTextInput source="description" label="Description" validate={[required()]} />
      </MultiLangBlock>
      <DisabledInput source="accessCode" validate={[required()]} />
      <CurrencyInput source="dailyCost" />
      <CurrencyInput source="dailyFoodCost" />
    </SimpleForm>
  </Edit>
);

export const GuideCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} autoFocus />
      <LLRUploadInput
        source="imageUrl"
        type="image"
        label="Image (650x400)"
        validate={[required()]}
      />
      <TextInput source="email" validate={[required()]} />
      <TextInput source="phoneNumber" validate={[required()]} />
      <DisabledInput
        source="accessCode"
        defaultValue={() => 'G-' + generatePassword()}
        validate={[required()]}
      />
      <MultiLangBlock>
        <LongTextInput source="description" label="Description" validate={[required()]} />
      </MultiLangBlock>
      <CurrencyInput source="dailyCost" />
      <CurrencyInput source="dailyFoodCost" />
    </SimpleForm>
  </Create>
);
