import React from 'react';
import classNames from 'classnames';
import { Datagrid as RADatagrid } from 'react-admin';
import { withStyles } from '@material-ui/core';

const styles = {
  cell: {
    '& a': {
      textDecoration: 'none',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
    '& a:hover span': {
      textDecoration: 'underline',
    },
    '& a>span': {
      display: 'inline-block',
    },
    '& a[role="button"]>span': {
      display: 'inherit',
    },
  },
  cellWithoutActions: {
    '&:first-child': {
      paddingLeft: '30px',
    },
  },
};

const Datagrid = withStyles(styles)(({ children, classes, ...restProps }) => {
  const cellClass = classNames(classes.cell, {
    [classes.cellWithoutActions]: !restProps.hasBulkActions,
  });
  return (
    <RADatagrid classes={{ headerCell: cellClass, rowCell: cellClass }} {...restProps}>
      {children}
    </RADatagrid>
  );
});
Datagrid.displayName = 'Datagrid';

export default Datagrid;
