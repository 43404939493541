import { ImageField } from 'react-admin';

const PROPTYPES_ERRORS_TO_IGNORE = [
  'Warning: Failed prop type: Invalid prop `translateChoice` of type `boolean` supplied to `ReferenceField`',
];

const runHacks = () => {
  // Make ImageFields have label by default
  if (!ImageField.defaultProps) {
    ImageField.defaultProps = {};
  }
  ImageField.defaultProps.addLabel = true;

  // Allow filtering PropTypes warnings to remove those from react-admin that don't make sense
  const oldConsoleError = console.error;
  console.error = (...args) => {
    try {
      const errorStr = args[0];
      if (errorStr.startsWith('Warning: Failed prop type')) {
        for (const propTypeErrorToIgnore of PROPTYPES_ERRORS_TO_IGNORE) {
          if (errorStr.startsWith(propTypeErrorToIgnore)) {
            return;
          }
        }
      }
      oldConsoleError(...args);
    } catch {
      oldConsoleError(...args);
    }
  };
};

export default runHacks;
