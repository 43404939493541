import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactS3Uploader from 'react-s3-uploader';
import { showNotification } from 'react-admin';
import UploadInput from './UploadInput';

const S3UploadInput = ({ getSignedUrl, accept, extension, dispatch, ...restProps }) => {
  const { type, source } = restProps;
  function getDefaultAccept () {
    if (type === 'image') {
      return 'image/*';
    } else if (type === 'file') {
      return '*';
    } else {
      throw new Error(`Unknown type ${ type }, must be file or image.`);
    }
  }
  function preprocess (file, next) {
    if (!extension || file.name.endsWith('.' + extension)) {
      next(file);
    } else {
      dispatch(showNotification('Invalid file extension. Expected .' + extension, 'warning'));
    }
  }
  return (
    <UploadInput {...restProps}>
      {({ onProgress, onError, onFinish }) => (
        <ReactS3Uploader
          id={source}
          getSignedUrl={getSignedUrl}
          accept={accept || getDefaultAccept()}
          preprocess={preprocess}
          onProgress={onProgress}
          onError={onError}
          onFinish={result => onFinish(result.publicUrl)}
          uploadRequestHeaders={{
            'x-amz-acl': 'public-read',
          }}
          contentDisposition="auto"
        />
      )}
    </UploadInput>
  );
};

S3UploadInput.propTypes = {
  getSignedUrl: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['file', 'image']),
  accept: PropTypes.string,
  extension: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(S3UploadInput);
