import React from 'react';
import PropTypes from 'prop-types';

const ConditionalField = ({ children, showIf, record, ...restProps }) => {
  let shouldShow;
  if (typeof showIf === 'boolean') {
    shouldShow = showIf;
  } else if (typeof showIf === 'string') {
    shouldShow = Boolean(record[showIf]);
  } else if (typeof showIf === 'function') {
    shouldShow = showIf(record);
  } else {
    throw new Error('Prop showIf is not of know type (boolean, string or function): ' + showIf);
  }

  if (shouldShow) {
    return React.Children.map(children, child => {
      return React.cloneElement(child, {
        record,
        ...restProps,
      });
    });
  } else {
    return null;
  }
};

ConditionalField.defaultProps = {
  addLabel: true,
};

ConditionalField.propTypes = {
  children: PropTypes.node.isRequired,
  showIf: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]).isRequired,
  record: PropTypes.object,
};

export default ConditionalField;
