import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  EmailField,
  required,
  Filter
} from 'react-admin';
import Datagrid from './detail/Datagrid';
import ShowButton from './button/ShowButton';
import CurrencyField from './fields/CurrencyField';
import CurrencyInput from './inputs/CurrencyInput';

const TITLE_SINGULAR = 'Transfer';
const TITLE_PLURAL = 'Transfers';

const TransferTitle = ({ record }) => (
  <span>
    {TITLE_SINGULAR} "{record.name}"
  </span>
);
TransferTitle.propTypes = { record: PropTypes.shape({ name: PropTypes.string }) };

const TransferFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q__companyName" alwaysOn />
  </Filter>
);

export const TransferList = props => (
  <List title={TITLE_PLURAL} {...props} sort={{ field: 'companyName', order: 'ASC' }} filters={<TransferFilters />}>
    <Datagrid rowClick="edit">
      <TextField source="companyName" />
      <TextField source="phoneNumber" />

      <ShowButton />
    </Datagrid>
  </List>
);

export const TransferShow = props => (
  <Show title={<TransferTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="companyName" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="contactName" />
      <CurrencyField source="transferInCost" />
      <CurrencyField source="transferoutCost" />
      <CurrencyField source="luggageTransferCost" />
    </SimpleShowLayout>
  </Show>
);

export const TransferEdit = props => (
  <Edit title={<TransferTitle />} {...props}>
    <SimpleForm>
      <TextInput source="companyName" validate={[required()]} />
      <TextInput source="email" validate={[required()]} />
      <TextInput source="phoneNumber" validate={[required()]} />
      <TextInput source="contactName" validate={[required()]} />
      <CurrencyInput source="transferInCost" />
      <CurrencyInput source="transferoutCost" />
      <CurrencyInput source="luggageTransferCost" />
    </SimpleForm>
  </Edit>
);

export const TransferCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="companyName" validate={[required()]} autoFocus />
      <TextInput source="email" validate={[required()]} />
      <TextInput source="phoneNumber" validate={[required()]} />
      <TextInput source="contactName" validate={[required()]} />
      <CurrencyInput source="transferInCost" />
      <CurrencyInput source="transferoutCost" />
      <CurrencyInput source="luggageTransferCost" />
    </SimpleForm>
  </Create>
);
